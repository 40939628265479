// helpers
import { formatMoney, displayPercent, pos_neg } from '../Helpers'


 function RetsBand({
  isAuthenticated,
  user,
  userAccountData
  }) 
  { 
    return (
      <div className="w-full pb-2 z-20 shadow-[rgba(0,15,0,0.1)_0px_-2px_3px_2px] shadow-[#5C8374]">
          <div className="flex flex-col items-center justify-center w-full">
            <div className='w-full flex flex-col'>
              {userAccountData?
                <div className='py-1 flex flex-row text-[#93B1A6] justify-center gap-2'>
                  <span>{formatMoney(userAccountData.balance.client_cap)} USDT</span>
                  <span>→ {pos_neg(userAccountData.balance.client_pnl)}{formatMoney(userAccountData.balance.client_pnl)} USDT 
                    ({pos_neg(userAccountData.balance.client_rets)}{displayPercent((userAccountData.balance.client_rets))})</span>
                </div>
                :
                <div className='py-1 flex flex-row text-[#93B1A6] justify-center gap-2'>
                  <span>- </span>
                  <span>PNL: - (-)</span>
                </div>
                }
            </div>        
          </div>
      </div>
    )
  }
  
  
  export default RetsBand