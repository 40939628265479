//React
import React from 'react';
//components
import Layout from "../hocs/Layout"

const Error404 = () => {
    return(
        <Layout>
            <div className="text-orange-500">
                Error404
            </div>
        </Layout>
    )
}

export default Error404