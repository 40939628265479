import bground from '../../assets/videos/trade1.mp4'
import { NavLink } from 'react-router-dom'
export default function UsHome() 
  {
    return (
      <>
        <div className="py-10 w-full h-auto bg-[#040D12] flex justify-center">
          <div className='px-6 md:px-10 md:max-w-6xl  flex flex-col md:flex-row text-[#93B1A6]'>
            <h3 className='w-full md:w-1/3 pb-5 text-sm md:text-xl font-semibold'>Quienes somos?</h3>
            <p className='md:w-2/3 text-justify text-sm md:text-lg'>Somos un fondo de inversión que se sirve del poder de la ciencia para encontrar los mejores 
            usos de capital y la conveniencia de la técnología para automatizar toda nuestra operación y generar los más altos
            rendimientos a mediano plazo para nuestros clientes. Nuestro objetivo es ser el fondo más exitoso de latinoamerica 
            que ofrece exposicion a cryptoactivos y tecnologías modernas.   </p>
          </div>          
        </div>
        <div className="py-6 md:py-8 w-full h-auto bg-[#93B1A6] text-[#040D12] flex justify-center">
          <div className='px-6 md:px-10 py-2 md:max-w-6xl flex flex-col md:flex-row items-center'>
            {/* texto */}
            <div className='w-full md:w-1/2 order-last md:order-first md:mr-4'>
              <h4 className='text-xl font-bold md:text-2xl '>
                Innovación en la nueva revolución en las Finanzas...
              </h4>
              <p className='py-4 text-justify text-md md:text-lg'>
                Cada día, reimaginamos y perfeccionamos nuestras estrategias, modelos y tecnología en busca de resultados 
                rendimiento superiores.
              </p>
              <div className='w-full flex justify-end'>
                
                <NavLink to="/whatwedo" className='rounded-sm text-sm md:text-md relative p-2 text-white bg-[#365486] items-self-end hover-underline hover:bg-[#5C8374]'>
                  Conoce que hacemos
                </NavLink>

              </div>
            </div>
            {/* video */}
            <div className='w-full md:w-1/2 md:pd-4 pb-2 flex justify-center'>
              <video
              className="w-full md:w-[450px] object-cover"
              src={bground}
              autoPlay
              loop
              muted
              />
            </div>
          </div>

        </div>
      
      </>
    )
  }
  