//react
import {useState, Fragment} from 'react'
//assets
// helperse
//redux
import { register } from '../Helpers'
//ux
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle} from '@headlessui/react'
import { LineWave } from 'react-loader-spinner'
import {
    BugAntIcon,
    IdentificationIcon,
    PaperClipIcon,
  } from '@heroicons/react/24/solid'

 function Boss(
  ) 
  { 
    const [openAddUser, setOpenAddUser] = useState(false)

    const [loading, setLoading] = useState(false);
    const [created, setCreated] = useState(null);
    const [formData, setFormData] = useState({
        id_run:'',
        email:'',
        username:'',
        password:''
    });
    
    const {
        id_run,
        email,
        username,
        password,
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value})


    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        console.log(formData)
        const registerd = await register(id_run, username, email, password)
        console.log(registerd)
        if (registerd) {
            setCreated(registerd)
            setLoading(false)
            console.log('se registro exitosamente')
        } else {
            setLoading(false)
            console.error('Failed to create user');
        }
    }

    const closeAddUser = e => {
        e.preventDefault();
        setOpenAddUser(false)
        setLoading(false)
        setCreated(null)
        setFormData({
            email:'',
            username:'',
            password:''
        })
    }

    const handleUpdateCSV = async () =>  {
        alert("Lo estás haciendo muy bien!")
    }



    return (
      <div className="relative w-full flex flex-row py-1">
        <div className='w-1/3 flex justify-center'
        onClick={handleUpdateCSV}
        >
            <IdentificationIcon className= 'px-2 text-gray-800 h-4'/>
        </div>
        <div className='w-1/3 flex justify-center'>
            <PaperClipIcon className= 'px-2 text-gray-800 h-4'/>
        </div>
        <div className='w-1/3 flex justify-center'>
            <button
            onClick={e=>setOpenAddUser(true)}
            >
                <BugAntIcon className= 'px-2 text-gray-800 h-4 cursor-help'/>
            </button>
        </div>

        {/*Box addUser */}
        <Transition show={openAddUser} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setOpenAddUser}>
                <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    {/*Pane que se abrepara hacer login*/ }
                    <DialogPanel className="relative transform overflow-hidden rounded-md bg-stone-900 p-2 text-left shadow-xl transition-all sm:my-8 w-auto">
                        <div>
                            <div className="mx-auto flex h-8 w-8 items-center justify-center rounded-full bg-stone-600">
                                
                                    <BugAntIcon className="h-6 w-6 text-green-700" aria-hidden="true" />

                            </div>
                            <div className="mt-1 text-center sm:mt-1">
                                <DialogTitle as="h3" className="text-lg font-medium leading-6 text-green-700">
                                Adiciona un Usuario
                                </DialogTitle>
                                
                            </div>
                        </div>
                        <form onSubmit={e=>{onSubmit(e)}} className="mt-2 sm:mt-2">
                            {created&&created.email?
                            <div className='w-full h-full flex- flex-col justify-center text-white'>
                                <div className='font-semibold py-2 text-center'>
                                    Usuario Creado
                                </div>
                                <div className='text-sm py-1 text-center'>
                                id: {created.id}
                                </div>
                                <div className='text-sm py-1 text-center'>
                                idRun: {created.id_run}
                                </div>
                                <div className='text-sm py-1 text-center'>
                                email: {created.email}
                                </div>
                                <div className='text-sm py-1 text-center'>
                                user_name: {created.user_name}
                                </div>
                                <div className='text-sm py-1 text-center'>
                                pass: {formData.password}
                                </div>
                                <button
                                className="mt-2 inline-flex w-full justify-center rounded-sm border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-500 "
                                onClick={e=>{closeAddUser(e)}}
                                >
                                    <span>Salir</span>
                                </button>
                                
                               
                            </div>:
                            <div className='w-full h-full'>
                                <div className="mx-8">
                                    <div className='my-1'>
                                        <label htmlFor="id_run" className="sr-only">id_run</label>
                                        <input
                                        id="id_run"
                                        name="id_run"
                                        value={id_run}
                                        onChange={e=>onChange(e)}
                                        type="text"
                                        className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-slate-900 placeholder-gray-500 focus:z-10 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                                        placeholder="id_run"
                                        />
                                    </div>
                                    <div className='my-1'>
                                        <label htmlFor="username" className="sr-only">username</label>
                                        <input
                                        id="username"
                                        name="username"
                                        value={username}
                                        onChange={e=>onChange(e)}
                                        type="text"
                                        className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-slate-900 placeholder-gray-500 focus:z-10 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                                        placeholder="username"
                                        />
                                    </div>
                                    <div className='my-1'>   
                                        <label htmlFor="email-address" className="sr-only">
                                        Correo electrónico
                                        </label>
                                        <input
                                        id="email-address"
                                        name="email"
                                        value={email}
                                        onChange={e=>onChange(e)}
                                        type='email'
                                        placeholder="Email"
                                        className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-slate-900 placeholder-gray-500 focus:z-10 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                                        required
                                        />
                                    </div>
                                    <div className='my-1'>   
                                        <label htmlFor="password" className="sr-only">
                                        Contraseña
                                        </label>
                                        <input
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={e=>onChange(e)}
                                        type='password'
                                        placeholder="Contraseña"
                                        className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-slate-900 placeholder-gray-500 focus:z-10 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                                        required
                                        />
                                    </div>

                                </div>
                                <div>
                                    {!loading?
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-500 "
                                        >
                                            <span>Crear</span>
                                        </button>
                                        :
                                        <div className='flex flex-col items-center justify-center '>
                                            <LineWave
                                            height="80"
                                            width="80"
                                            color="#fbbf24"
                                            ariaLabel="line-wave-loading"
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            }
                            
                        </form>
                    </DialogPanel>
                    </TransitionChild>
                </div>
                </div>
            </Dialog>
        </Transition>

          
      </div>
    )
  }
  
  
  export default Boss