import Plot from 'react-plotly.js';
import React, { useState, useEffect } from 'react';

function PlotHist({ dataHist, userTit }) {
    const [date, setDate] = useState(null);
    const [value, setValue] = useState(null);
    const [value2, setValue2] = useState(null);
    const [value3, setValue3] = useState(null);
    const [value4, setValue4] = useState(null);


    useEffect(() => {
      if (dataHist) {
          const millisecondsArray = Object.values(dataHist.datetime);
          const dateStringsArray = millisecondsArray.map((ms) => {
              const date = new Date(ms);
              return date;
          });
          setDate(dateStringsArray);
          setValue(Object.values(dataHist.ret_cumms));
          setValue2(Object.values(dataHist.SP500));
          setValue3(Object.values(dataHist.BTCUSDT));
          setValue4(Object.values(dataHist.ORO));
   
          
      }
  }, [dataHist]);


    return (
        <div className="mx-auto flex justify-center w-full md:w-auto overflow-hidden"
            style={{
                paddingTop: '1px', paddingBottom: '1px',
                marginTop: '1px',
                display: 'flex', justifyContent: 'center'
            }}>
            <Plot
                data={[
                    {
                        x: date,
                        y: value,
                        
                        line: { color: '#fafafa' },
                        name: `${userTit}`,
                        type: 'scatter',
                        mode: 'lines',
                        xaxis: 'x',
                        yaxis: 'y'
                    },
                    {
                        x: date,
                        y: value2,
                        
                        line: { color: '#295F98' },
                        name: 'SP500',
                        type: 'scatter',
                        mode: 'lines',
                        xaxis: 'x',
                        yaxis: 'y',
                        visible:'legendonly'
                    },
                    {
                        x: date,
                        y: value3,
                        
                        line: { color: '#FF885B' },
                        name: 'BTCUSDT',
                        type: 'scatter',
                        mode: 'lines',
                        xaxis: 'x',
                        yaxis: 'y',
                        visible: 'legendonly'
                    },
                    {
                        x: date,
                        y: value4,
                        
                        line: { color: '#FCDE70' },
                        name: 'ORO',
                        type: 'scatter',
                        mode: 'lines',
                        xaxis: 'x',
                        yaxis: 'y',
                        visible:'legendonly'
                    },
                 
                ]}
                layout={{
                    dragmode: 'pan',
                    xaxis: {
                      autorange: true,
                      tickformat: '%d-%b \n %Y', // Format string for day-month-year
                      type: 'date',
                      tickfont: { color: 'white' },
                
                  },
                      yaxis: {
                        autorange: true,
                        side: 'left',
                        type: 'linear',
                        tickfont: { color: 'white' },
                        tickformat: '.1%',
                    },
                    
                    autosize: true,
                    hovermode: "x unified",
                    showlegend: true,
                    legend: {
                        x: 0, // Adjust the horizontal position of the legend inside the plot
                        y: 1.2, // Adjust the vertical position of the legend inside the plot
                        orientation: "h",
                        font: {
                            family: 'sans-serif',
                            size: 7,
                            color: 'gray'
                          },
                    },
                    margin: {
                        l: 50, // Left margin
                        r: 5, // Right margin
                        t: 20, // Top margin
                        b: 50, // Bottom margin
                    },
                    paper_bgcolor: "#040D12",
                    plot_bgcolor: "#040D12",
                }}
                config={{ responsive: true, displayModeBar: false }}
                className='h-[250px] w-[342px] md:h-[310px] md:w-[420px] lg:h-[320px] lg:w-[500px]'
            />
        </div>
    );
}

export default PlotHist;
