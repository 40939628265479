//redux conections
import { connect } from 'react-redux';
import { useState } from "react";

//components
import Layout from '../hocs/Layout';
import BannerHome from '../components/home/BannerHome';

//helpers
import { contac_mss } from '../components/Helpers';

//UI

import { LineWave } from 'react-loader-spinner';

const Contact = () => {


    const [mssLoading, setMSSLoading] = useState()
    const [formDataMSS, setFormDataMSS] = useState({
        asunto:'',
        msg:'',
        email: '',
        tel:''
      });
    
      const onChangeMsg = e => setFormDataMSS({ ...formDataMSS, [e.target.name]: e.target.value})
    
      const {
            asunto,
            msg,
            email,
            tel
      } = formDataMSS;

      const onSubmitMSS =  async (e) => {
        if(msg===''){
            alert('El mensaje está vacio.')
        }else{
            e.preventDefault();
            setMSSLoading(true)
            console.log(formDataMSS)
            const sent = await contac_mss(asunto, msg, email, tel)
            console.log(sent)
            if (sent === true) {
                setMSSLoading(false)
                setFormDataMSS({
                        asunto:'',
                        msg:'',
                        email: '',
                        tel:''
                        })
                    alert('Se envió correctamente el mensaje.')
            } else {
                setMSSLoading(false)
                alert('Error enviando el mensaje. Intenta de nuevo.');
            }
        }
    }




    return(

        <Layout>
            <BannerHome/> 
            <div className="relative w-full z-20 shadow-[rgba(0,15,0,0.1)_0px_-2px_3px_2px] shadow-[#5C8374]">
            </div>
            <div className='text-[#93B1A6] p-4 md:py-20 flex flex-col justify-center items-center'>
                <form onSubmit={e=>setMSSLoading(e)} className="mt-4 w-full md:w-1/2">
                    <div className="text-sm md:text-lg">
                        <div>   
                            <label htmlFor="asunto" className='text-[#93B1A6] flex items-start pt-2'>
                            Asunto:
                            </label>
                            <input
                            id="asunto"
                            name="asunto"
                            value={asunto}
                            onChange={e=>onChangeMsg(e)}
                            type='text'
                            className="mb-1 text-xs md:text-base text-left relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-gray-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div>   
                            <label htmlFor="msg" className='text-[#93B1A6] flex items-start pt-2'>
                            Mensaje:
                            </label>
                            <textarea
                            id="msg"
                            name="msg"
                            rows={4}
                            value={msg}
                            onChange={e=>onChangeMsg(e)}
                            type='text'
                            className="mb-1 text-xs md:text-base text-left relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-gray-500 focus:z-10 sm:text-sm"
                            required
                            />
                            
                        </div>
                        <div className='pt-6 text-[#93B1A6] pb-1'>
                            Datos de contacto:
                        </div>
                        <div className='flex flex-row items-center md:mb-10'>
                            <div className='w-2/3'>
                                <div>   
                                    <label htmlFor="email-address" className="sr-only">
                                    Correo electrónico
                                    </label>
                                    <input
                                    id="email-address"
                                    name="email"
                                    value={email}
                                    onChange={e=>onChangeMsg(e)}
                                    type='email'
                                    placeholder="Email"
                                    className="mb-1 text-xs md:text-base text-left relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-gray-500 focus:z-10 sm:text-sm"
                                    
                                    />
                                </div>
                                <div>   
                                    <label htmlFor="tel" className="sr-only">
                                    Número de Contacto
                                    </label>
                                    <input
                                    id="tel"
                                    name="tel"
                                    value={tel}
                                    onChange={e=>onChangeMsg(e)}
                                    type='tel'
                                    placeholder="Numero de Contacto"
                                    className="mb-1 text-xs md:text-base text-left relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-gray-500 focus:z-10 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <button
                            type="submit"
                            className="w-1/3 h-1/2 py-2 m-2 px-10text-white cursor-pointer rounded-sm bg-[#365486] hover:bg-stone-700 text-white"
                            onClick={onSubmitMSS}
                            >
                                {
                                mssLoading===true?
                                <div className='relative h-6 flex justify-center w-full items-self-end overflow-hidden'>
                                    <div className='absolute flex justify-center ml-6 -bottom-3'>
                                    <LineWave
                                        height="70"
                                        width="70"
                                        color="white"
                                        ariaLabel="line-wave-loading"
                                        />
                                    </div>
                                </div>
                                :
                                <span className='h-6'>Enviar</span>
                                }
                            </button>
                        </div>
                        
                                
                            
                        
                    </div>
                  
                </form>
            </div>
        </Layout>
           
        
    )
}

const mapStateToProps = state => ({


})

export default connect(mapStateToProps,{


}) (Contact)