import{

    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
   
    SET_AUTH_LOADING,
    REMOVE_AUTH_LOADING,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,

    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_CONFIRM_SUCCESS,
    RESET_PASSWORD_CONFIRM_FAIL,

} from './types'

import axios from 'axios'




//Acontinuacion funcion para cargar datos de usuario
//Envía el acceso token almacenado en cookies retorna los datos de usuario
export const load_user = () => async dispatch =>{
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config);
            if(res.status === 200){
                dispatch({
                    type: USER_LOADED_SUCCESS,
                    payload:res.data
                })
            }else{
                dispatch({
                    type: USER_LOADED_FAIL
                })
            }
        }catch(err){
           
            dispatch({
                type: USER_LOADED_FAIL
            })
        }
    }else{
        dispatch({
            type: USER_LOADED_FAIL
        })

    }

}


//Acontinuacion funcion para hacer login
//Envía email y password, retorna access tokenJWT de acces y token de refresh que se almacenaran en localstorage
export const login = (user_name, password) => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    });
    console.log('trying log')
    const config ={
        headers:{
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({
        user_name,
        password
    });

    
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config);
        if (res.status === 200){    
            dispatch({
                type: LOGIN_SUCCESS,
                payload:res.data
            });
            dispatch(load_user());

            dispatch({
                type: REMOVE_AUTH_LOADING,
            });
            return res.status
        }
    }catch(err){
        if (err.response) {
            const status = err.response.status;
        
        // Handle unauthorized (401) case
        if (status === 401) {
            dispatch({
                type: LOGIN_FAIL
            });
            dispatch({
                type: REMOVE_AUTH_LOADING
            });
            return 401; // Return 401 for unauthorized
        }
        dispatch({
            type: LOGIN_FAIL,
        });
        dispatch({
            type: REMOVE_AUTH_LOADING,
        });
        return status // Return the actual error status code
        } else {
            // Handle network/server errors (no response from server)
            dispatch({
                type: LOGIN_FAIL
            });
            dispatch({
                type: REMOVE_AUTH_LOADING
            });
            return 500; // Default to 500 for server errors
        }
    }
} 

//Acontinuacion funcion para checar si estamos autenticados
//Envía el access token de los cookies para verificación, retorna token 
export const check_authenticated = (email, password) => async dispatch => {
    if (localStorage.getItem('access')){

        const config = {
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        const body = JSON.stringify({
            token: localStorage.getItem('access')
        });

        try{
            
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify`, body, config)
            if (res.status === 200){
                
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                })
            }else{
                //console.log('authentication fail1')
                dispatch({
                    type: AUTHENTICATED_FAIL
                })
            }

        }catch(err){
            //console.log('authentication fail2')
            dispatch({
                
                type: AUTHENTICATED_FAIL
            })
        }

    } else{
        //console.log('authentication fail3')
        dispatch({
            type: AUTHENTICATED_FAIL
        })
    }
    
}


//Acontinuacion funcion para refrescar el access token
//Envía el refresh token de los cookies para verificación, retorna token 
export const refresh = () => async dispatch => {
    if (localStorage.getItem('refresh')){
        const config ={
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        const body = JSON.stringify({
            refresh: localStorage.getItem('refresh')
        });

        try{
           
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`, body, config)
            if (res.status === 200){
                //console.log('refresh success')
                dispatch({
                    type: REFRESH_SUCCESS,
                    payload: res.data
                })
            }else{
                //console.log('refresh fail1')
                dispatch({
                    type: REFRESH_FAIL
                })
            }

        }catch(err){
            //console.log('refresh fail2')
        }
    }else{
        //console.log('refresh fail3')
        dispatch({
            type: REFRESH_FAIL
        });
    }
}

//A continuacion funcion para reseter nuestra contraseña
export const reset_password = (email) => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    })
    const config ={
        headers:{
            'Content-Type': 'application/json'
        }
    };
    const body = JSON.stringify({email});

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, body, config)

        if (res.status === 204){
            dispatch({
                type: RESET_PASSWORD_SUCCESS,
            })
            //dispatch(setAlert('Te hemos enviado un correo','green'));
            dispatch({
                type: REMOVE_AUTH_LOADING,
            })
        }else{
            dispatch({
                type: RESET_PASSWORD_FAIL
            })
            dispatch({
                type: REMOVE_AUTH_LOADING
            })
            //dispatch(setAlert('Error con el servidor','error'));
        }
    }catch(err){
        dispatch({
            type: REFRESH_FAIL
        })
        dispatch({
            type: REMOVE_AUTH_LOADING
        })
    }
}

//A continuacion funcion para confirmar que la constraseña ha cambiado
export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    })
    const config ={
        headers:{
            'Content-Type': 'application/json'
        }
    };
    const body = JSON.stringify({
        uid,
        token,
        new_password,
        re_new_password,
    });


    if (new_password !== re_new_password){
      
        dispatch({
            type: RESET_PASSWORD_CONFIRM_FAIL
        })
        dispatch({
            type: REMOVE_AUTH_LOADING
        })
    }else{
        try{
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config)
            if (res.status === 204){
                alert("La constraseña ha sido restablecida con exito")
                dispatch({
                    type: RESET_PASSWORD_CONFIRM_SUCCESS
                })
                dispatch({
                    type: REMOVE_AUTH_LOADING
                })

            }else{
                dispatch({
                    type: RESET_PASSWORD_CONFIRM_FAIL
                })
                dispatch({
                    type: REMOVE_AUTH_LOADING
                })
            }
        }catch(err){
            dispatch({
                type: RESET_PASSWORD_CONFIRM_FAIL
            })
            dispatch({
                type: REMOVE_AUTH_LOADING
            })
        }
    }
}

export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
    //dispatch(setAlert('Logout succesfull','green'))
}
