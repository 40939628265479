import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
   
    SET_AUTH_LOADING,
    REMOVE_AUTH_LOADING,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,

    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_CONFIRM_SUCCESS,
    RESET_PASSWORD_CONFIRM_FAIL,

} from '../actions/types'

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isRegistered: null,
    user: null,
    loading: false,
    user_loading: true,
}

export default function auth(state = initialState, action){
    const {type, payload} = action;

    switch(type){

        //STATES FOR LOGIN AND AUTHENTICATION
        case USER_LOADED_SUCCESS:
            // console.log("payload")
            // console.log(payload)
            return{
                ...state,
                user: payload,
                user_loading: false
            }
        case USER_LOADED_FAIL:
            //console.log("removing user")
            return{
                ...state,
                user: null,
                user_loading: false
            }
        case SET_AUTH_LOADING:
            return{
                ...state,
                loading: true
            }
        case REMOVE_AUTH_LOADING:
            return{
                ...state,
                loading: false
            }
        case AUTHENTICATED_SUCCESS:
            return{
                ...state,
                isAuthenticated: true,
            }
        case AUTHENTICATED_FAIL:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return{
                ...state,
                isAuthenticated: false,
                access:null,
                refresh:null
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('access', payload.access);
            localStorage.setItem('refresh', payload.refresh);
            return{
                ...state,
                isAuthenticated: true,
                access: localStorage.getItem('access'),
                refresh: localStorage.getItem('refresh')
                
            }
        case REFRESH_SUCCESS:
            localStorage.setItem('access', payload.access)
            localStorage.setItem('refresh', payload.refresh)
            return{
                ...state,
                access: localStorage.getItem('access'),
                refresh: localStorage.getItem('refresh')
            }
        
        case RESET_PASSWORD_SUCCESS:
        case RESET_PASSWORD_FAIL:
        case RESET_PASSWORD_CONFIRM_SUCCESS:
        case RESET_PASSWORD_CONFIRM_FAIL:
            return{
                ...state
            }   
        
        case LOGIN_FAIL:
        case REFRESH_FAIL:
        case LOGOUT:
            //console.log('removing access and refresh')
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return{
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null
            } 
            
        default:
            return state

    }

}