import {
    GET_HOME_DATA_SUCCES,
    GET_HOME_DATA_FAIL,

} from '../actions/types'


const initialState = {
    homeData: null,
    
}

export default function homeData(state = initialState, action){
    const {type, payload} = action;

    switch(type){

        //STATES FOR LOGIN AND AUTHENTICATION
        case GET_HOME_DATA_SUCCES:
            return{
                ...state,
                homeData: payload,
            }
        case GET_HOME_DATA_FAIL:
            return{
                ...state,
                homeData: null,
            }
            
        default:
            return state

    }

}