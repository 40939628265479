//react
import { useEffect, useState } from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom';
//redux
import { connect } from 'react-redux'
//redux actions
import {login, check_authenticated, refresh, load_user, logout} from "../../redux/actions/auth";
import { request_reset_psw } from '../Helpers';
//UI
import {
  Cog8ToothIcon,
  XMarkIcon,
  ArrowTrendingUpIcon,
  ClipboardDocumentListIcon,
  RocketLaunchIcon,
  BriefcaseIcon,
} from '@heroicons/react/24/solid'
import {ReactComponent as LogoUser} from '../../assets/logos/logo_user.svg';
import {ReactComponent as Sw} from '../../assets/logos/ALTRA.svg';
import { LineWave } from 'react-loader-spinner'

//helpers
import { change_password } from '../Helpers';
//components

function Navbar({
  login,
  isAuthenticated,
  check_authenticated,
  refresh,
  load_user,
  user,
  logout,
}) {

  //GENERALES
  useEffect(() => {
  }, [])

  //MENU LATERAL
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [authCompleted, setAuthCompleted] = useState(false);
  const [openLogoutBox, setOpenLogoutBox] = useState(false)
  const [openResetPSSW, setOpenResetPSSW] = useState(false)
  const [openConfig, setOpenConfig] = useState(false)
  const [loadingLogin, setLoadingLogin] = useState(false);


  const toggleMenu = e => {
    e.preventDefault()
    setOpenLogoutBox(false)
    setIsMenuOpen(!isMenuOpen);
    setOpenResetPSSW(false)
    };
  
  //Para logearse

  useEffect(() => {
    const authenticateUser = async () => {
      if (!authCompleted && !isAuthenticated) {
        await refresh();
        check_authenticated();
        load_user();
        setAuthCompleted(true);
        setLoadingLogin(false)
      }
    };

    authenticateUser();
  }, [isAuthenticated, refresh, load_user, check_authenticated, authCompleted]);

  const [loginFormData, setLoginFormData] = useState({
      userName:'',
      password:''
  });

  const onChange = e => setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value})

    const {
        userName,
        password
    } = loginFormData;


    const onSubmitLogIn = async (e) => {
      e.preventDefault();
      setLoadingLogin(true)
      const loginResponse = await login(userName, password)
      console.log(loginResponse)
      if (loginResponse ===200) {
          setLoadingLogin(false)
      }else if(loginResponse ===401){
          setLoadingLogin(false)
          alert('Contraseña o usuario incorrecto. Intenta de nuevo.')
      }else{
        setLoadingLogin(false)
        alert('No se pudo iniciar sesión.')
      }
    }


  //Para deslogearse
  
  const navigate=useNavigate()

  const handleLogout=()=>{
      setOpenLogoutBox(false)
      logout()
      navigate('/')
  }

  //Para solicitar contraseña olvidada
  const toggleResetPSW = () => {
    setOpenResetPSSW(!openResetPSSW);
    };
  const [resetPSWFormData, setResetPSWFormData] = useState({
    email:'',
  });
  const onChangeResetForm = e => setResetPSWFormData({ ...resetPSWFormData, [e.target.name]: e.target.value})
  const {
    email,
  } = resetPSWFormData;

  const [loadingChangePSW, setLoadingChangePSW] = useState(false);
  const onSubmitResetPSW = async (e) => {
    e.preventDefault();
    if (email===''){
      alert('Tienes que darnos tu email para poder resetear tu contraseña. Intenta de nuevo.')
    }else{
      setLoadingChangePSW(true)
      const changeResponse = await request_reset_psw(email)
      console.log(changeResponse)
      if (changeResponse) {
          setLoadingChangePSW(false)
          alert('Se envió solicitud de reseteo de contraseña. Te contactaremos en las proximás horas.')
          setResetPSWFormData(
            {email:''}
          )
          toggleMenu(e)
      } else {
          setLoadingChangePSW(false)
          alert('Fallo la solicitud. Intenta de nuevo.')
      }
    }
  }

  
  

  
  //cambiar contraseña
 
  const [changed, setChanged] = useState(false);

  const toggleConfigMenu = () => {
    setOpenConfig(!openConfig);
    setChanged(false)
    };

  const [changePSWFormData, setChangePSWFormData] = useState({
    new_password:"",
    current_password:""
  });
  
  const onChangeChangeForm = e => setChangePSWFormData({ ...changePSWFormData, [e.target.name]: e.target.value})  

  const {
    new_password,
    current_password,
  } = changePSWFormData;    

  

  const onSubmitChangePSW = async (e) => {
    e.preventDefault();
    setLoadingChangePSW(true)
    const changeResponse = await change_password(new_password,current_password)
    console.log(changeResponse)
    if (changeResponse) {
        setChanged(changeResponse)
        setLoadingChangePSW(false)
        console.log('Se cambio exitosamente')
    } else {
        setLoadingChangePSW(false)
        setChanged(false)
        alert('Fallo el cambio de contraseña, contactar admin.')
    }
  }

    

  return (
    <div className='relative'>
      {/* Principal Navbar */}
      <div className="fixed top-0 left-0 z-50 py-1 md:py-2 px-5 w-full flex justify-between bg-[#040D12] text-slate-300  shadow-md shadow-[#5C8374]">
        <Link to="/" className="flex flex-row items-center text-[#93B1A6]">
          <h1 className="ml-2 text-lg md:text-xl">
            ALTRASTACK
          </h1>
          <div className="">
            <Sw className='ml-2 w-[19px] h-[19px]'/>
          </div>
        </Link>
        <div className="flex flex-row items-center gap-3 text-sm md:text-lg text-[#93B1A6]">
          <div className='hidden sm:flex sm:flex-row items-center gap-3'>
            <NavLink to="/" className='cursor-pointer relative hover-underline'>
              Inicio
            </NavLink>
            <NavLink to="/whatwedo/" className='cursor-pointer relative hover-underline'>
              Que hacemos?
            </NavLink>
            <NavLink to="/contact/" className='cursor-pointer relative hover-underline'>
              Contacto
            </NavLink>
            {user&&isAuthenticated?
            <NavLink to="/investor/board/balance" className='cursor-pointer relative hover-underline'>
              Dashboard
            </NavLink>
            :
            <></>
            }
          </div>

          <button 
          className='px-1 py-1 group bg-[#365486] rounded-full hover:bg-[#5C8374] transition ease-in-out delay-100'
          title="Iniciar Sesión..." 
          onClick={toggleMenu}>
            
            <RocketLaunchIcon className='w-4 text-white transition ease-in-out delay-150' />
          </button>

        </div>
      </div>
      {/* Navbar Lateral */}
      <div>
        <div
        className={`fixed top-0 bottom-0 right-0 w-64 z-50 bg-[#93B1A6] shadow-lg transform transition-transform duration-300 ${
        isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        >
            <div className="flex flex-col pt-4 p-4 text-xs md:text-lg justify-end items-end">
              <div className='w-full py-2 flex flex-row justify-between'>
                <span>
                  
                </span>
                <XMarkIcon 
                onClick={toggleMenu}
                className='w-4 cursor-pointer hover:text-white'/>
              </div>
              <NavLink to="/" className='w-full py-2 border-b border-[#5C8374] cursor-pointer text-[#040D12] hover:text-white'>
                Inicio
              </NavLink>
              <NavLink to="/whatwedo" className='w-full py-2 border-b border-[#5C8374] cursor-pointer text-[#040D12] hover:text-white'>
                Que hacemos?
              </NavLink>
              <NavLink to="/contact" className='w-full py-2 border-b border-[#5C8374] cursor-pointer text-[#040D12] hover:text-white'>
                Contacto
              </NavLink>
              {/* INICIAR SESION/SECCION USUARIO */}
              <div className='absolute bottom-0 right-0 w-full border-t border-[#040D12]'>
                {isAuthenticated&&user?
                <>
                {/* CONFIGURACIONES USUARIO/ CAMBIAR CONTRASEÑA */}
                {
                openConfig?
                <form className='flex flex-col text-xs md:text-lg px-1 items-center'>
                  <div className='w-full text-left'>Cambiar Contraseña</div>
                  {loadingChangePSW?
                  <div className='ml-2 -mt-4 py-5'>
                    <LineWave
                    height="70"
                    width="70"
                    color="white"
                    ariaLabel="line-wave-loading"
                    />
                  </div>:
                  <>
                  {changed?
                  <div className='p-2 h-20 text-[#040D12] text-justify text-xs md:text-lg'>La contraseña fue cambiada con exito!!</div>
                  :
                  <div className='w-full flex flex-col items-center'>
                    {/* input contraseñ */}
                    <div className='w-full py-1'>    
                      <label htmlFor="current_password" className="sr-only">
                      contraseña actual
                      </label>
                      <input
                      id="current_password"
                      name="current_password"
                      value={current_password}
                      onChange={e=>onChangeChangeForm(e)}
                      type='password'
                      placeholder="Contraseña Actual"
                      className="h-5 relative block w-full rounded-sm border border-[#5C8374] p-1 text-[#040D12] placeholder-[#5C8374] focus:z-10 focus:border-[#040D12] focus:outline-none focus:ring-[#040D12] text-[10px] md:text-base"
                      required
                      />
                    </div>
                    {/* input contraseñ */}
                    <div className='w-full pt-1'>    
                      <label htmlFor="new_password" className="sr-only">
                      Nueva Contraseña
                      </label>
                      <input
                      id="new_password"
                      name="new_password"
                      value={new_password}
                      onChange={e=>onChangeChangeForm(e)}
                      type='password'
                      placeholder="Nueva Contraseña"
                      className="h-5 relative block w-full rounded-sm border border-[#5C8374] p-1 text-[#040D12] placeholder-[#5C8374] focus:z-10 focus:border-[#040D12] focus:outline-none focus:ring-[#040D12] text-[10px]"
                      required
                      />
                    </div>
                    <div className='w-full flex justify-center'>
                        <button className='h-6 mb-2 w-2/3 mt-1 rounded-sm border text-white justify-self-center bg-[#040D12] border-[#040D12] hover:bg-[#5C8374]'
                        onClick={onSubmitChangePSW}
                        >
                          Cambiar
                        </button>
                    </div>
                  </div>
                  }
                  </>
                  }
              
                </form>:
                <></>
                }
                <div className='flex flex-row w-full border-b border-[#040D12]'>
                  <div className='h-5 w-5/6 bg-[#040D12]'></div>
                  <button className='w-1/6 flex justify-center hover:text-white bg-[#5C8374] text-[#040D12] items-center'
                  onClick={toggleConfigMenu}>
                    <Cog8ToothIcon className='px-2 h-4'/>
                  </button>
                </div>
                {/* LINK A DASHBOARD DE USUARIO */}
                <div className='flex flex-row items-center justify-center text-[#040D12] font-semibold'>
                  <h4>{(user.user_name).toUpperCase()} </h4> 
                  <LogoUser className='w-7 h-7'/>
                </div>
                <div className='flex-col pt-4 p-4 text-[#040D12]'>
                  <NavLink to="/investor/board/balance" className='w-full py-1 flex flex-row hover:text-[#5C8374] items-center'
                  >
                    <span>Balance</span>
                    <ArrowTrendingUpIcon className='px-2 h-4'/>
                  
                  </NavLink>
                  <NavLink to="/investor/board/portfolio" className='w-full py-1 flex flex-row hover:text-[#5C8374] items-center'>
                    <span >Portfolio</span>
                    <BriefcaseIcon className='px-2 h-4'/>
                    
                  </NavLink>
                  <NavLink to="/investor/board/transacs" className='w-full py-1 flex flex-row hover:text-[#5C8374] items-center'>
                    <span>Transacciones</span>
                    <ClipboardDocumentListIcon className='px-2 h-4'/>
                  </NavLink>
                </div>
                </>
                :
                <>
                </>
                }
                {/* LoginForm */}
                <form>
                  <div className={` p-2 flex flex-col
                  ${!isAuthenticated ? 'block' : 'hidden'}`
                  }>
                    {/* login */}
                    {openResetPSSW?
                    <></>:
                    <div className='text-xs md:text-base text-justify mb-4 flex flex-col items-center'>
                      {/* no tienes cuenta */}
                      <div>
                      Si no tienes una cuenta, solicita una
                      <Link to="/requestacc/" className='mx-1 font-semibold cursor-pointer underline hover:text-white'> 
                        aquí.
                      </Link>
                      </div>
                      <div className='mt-2 mb-1 w-1/2 self border border-b-2 border-[#5C8374]'
                      />
                      {/* input usuario */}
                      <div className='w-full'>   
                          <label htmlFor="user-name" className="sr-only">
                          Usuario
                          </label>
                          <input
                          id="user-name"
                          name="userName"
                          value={userName}
                          onChange={e=>onChange(e)}
                          type='text'
                          placeholder="Usuario"
                          className="h-8 relative block w-full appearance-none rounded-none rounded-t-md border border-[#5C8374] p-2 text-[#040D12] placeholder-[#5C8374] focus:z-10 focus:border-[#040D12] focus:outline-none focus:ring-[#040D12] sm:text-base"
                          required
                          />
                      </div>
                      {/* input contraseñ */}
                      <div className='w-full'>    
                        <label htmlFor="password" className="sr-only">
                        Contraseña
                        </label>
                        <input
                        id="password"
                        name="password"
                        value={password}
                        onChange={e=>onChange(e)}
                        type='password'
                        placeholder="Contraseña"
                        className="h-8 relative block w-full appearance-none rounded-none rounded-b-md border border-[#5C8374] p-2 text-[#040D12] placeholder-[#5C8374] focus:z-10 focus:border-[#040D12] focus:outline-none focus:ring-[#040D12] sm:text-base"
                        required
                        />
                      </div>
                    </div>
                    }
                    {/* olvidaste contraseña box */}
                    <div>
                      {!openResetPSSW?
                        <div className='mt-1 text-right text-xs md:text-base font-semibold cursor-pointer underline hover:text-white'
                        onClick={toggleResetPSW}>
                        Olvidaste tu contraseña?
                        </div>
                        :
                        <form className=' border-[#040D12] text-sm md:text-base'>
                          <h4 className='p-1 font-semibold bg-[#040D12] text-[#93B1A6]'>Recupera tu cuenta:</h4>
                          <p className='text-[12px] md:text-base mx-1'> Introduce el correo electronico con el que te registraste.</p>
                          <div className='mr-2'>   
                              <label htmlFor="email-address" className="sr-only">
                              Correo electrónico
                              </label>
                              <input
                              id="email-address"
                              name="email"
                              value={email}
                              onChange={e=>onChangeResetForm(e)}
                              type='email'
                              placeholder="Email"
                              className="m-1 p-1 w-full text-xs md:text-base  rounded-md text-[#040D12] placeholder-[#93B1A6]"
                              required
                              />
                          </div>
                          <p className='text-[12px] md:text-base  mx-1 text-justify'> Enviaremos una contraseña nueva a tu correo despúes de hacer verificaciones de seguridad. </p>
                          <div className='flex flex-row'>
                            
                            
                            
                            <button 
                            className='p-1 w-3/4 text-[#93B1A6] bg-[#040D12] hover:bg-[#5C8374] border border-[#93B1A6] rounded-md'
                            onClick={onSubmitResetPSW}
                            >
                            {loadingChangePSW?
                            <div className='relative h-6 flex justify-center w-full items-self-end overflow-hidden'>
                              <div className='absolute flex justify-center ml-6 -bottom-3'>
                                <LineWave
                                height="70"
                                width="70"
                                color="white"
                                ariaLabel="line-wave-loading"
                                />
                              </div>
                          </div>
                            :<span>Recuperar</span>
                            }

                            
                            </button>
                            
                            <button 
                              className='p-1 w-1/4 bg-white hover:bg-[#5C8374] border border-[#93B1A6] rounded-md flex justify-center'
                              onClick={toggleResetPSW}
                              ><XMarkIcon className='h-5 pt-1'
                              /></button>
                          </div>
                        </form>
                      }
                    </div>
                  </div>
                  {/* Iniciar/CerrarSesion */}
                  <div>
                    {isAuthenticated?
                    <div>
                      <div
                      className={`py-4 flex flex-col items-center bg-[#5C8374] border border-[#040D12]
                        ${openLogoutBox ? 'block' : 'hidden'}`
                        }
                      >
                        <div className='py-1'>¿Seguro quieres Salir?</div>
                        <div className='flex flex-row gap-4'>
                          <div 
                          className='px-3 h-6 w-10 bg-white hover:bg-[#93B1A6] border border-[#93B1A6] rounded-full cursor-pointer'
                          onClick={handleLogout}
                          >Si</div>
                          <div 
                          className='px-3 h-6 w-10 bg-white hover:bg-[#93B1A6] border border-[#93B1A6] rounded-full cursor-pointer'
                          onClick={toggleMenu}
                          ><XMarkIcon className='h-5 -mx-[3px]'
                          /></div>
                        </div>

                      </div>
                      <button 
                      className='text-white w-full cursor-pointer rounded-sm p-2 bg-[#040D12] items-self-end hover-underline hover:bg-[#5C8374] flex justify-center'
                      onClick={e => {
                        e.preventDefault()
                        setOpenLogoutBox(true);
                      }}
                      >
                        Cerrar Sesión
                      </button>
                    </div>
                    :
                    <>
                    {openResetPSSW?
                    <div 
                    className='text-[#5C8374] w-full rounded-sm p-2 items-self-end hover-underline hover:line-through flex justify-center'
                    >
                      Iniciar Sesión
                    </div>
                    :
                    <>
                    {loadingLogin?
                    <div className='relative h-10 flex justify-center w-full items-self-end bg-[#365486] overflow-hidden'>
                      <div className='absolute flex justify-center ml-6 -bottom-2'>
                      <LineWave
                        height="70"
                        width="70"
                        color="white"
                        ariaLabel="line-wave-loading"
                        />
                      </div>
                    </div>
                    :
                    <button 
                    className='text-white w-full cursor-pointer rounded-sm p-2 bg-[#365486] items-self-end hover-underline hover:bg-[#5C8374] flex justify-center'
                    onClick={onSubmitLogIn}
                    >
                      Iniciar Sesión
                    </button>
                    } 
                    </>
                    }
                    </>
                    }

                  </div>
                </form>
              </div>
            </div>
        </div>
        {isMenuOpen && (
            <div
            className="fixed inset-0 bg-black bg-opacity-50"
            onClick={toggleMenu}
            ></div>
        )}
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    user: state.auth.user
})

export default connect(mapStateToProps,{
    login,
    refresh,
    check_authenticated,
    load_user,
    logout
}) (Navbar)