import bground from '../../assets/videos/tech2.mp4'
export default function HeroSection() 
  {
    return (
      <div className="relative w-full h-[200px] overflow-hidden">
          <video
          className="absolute z-0 top-0 left-0 w-full h-[350px] object-cover"
          src={bground}
          autoPlay
          loop
          muted
          />
          <div className="pl-2 mt-16 relative flex items-center justify-center w-full h-1/4 text-cente z-20">
            <div>
              <h1 className="text-5xl md:text-7xl font-bold mb-4 z-10 text-slate-100 drop-shadow-[0_0.5px_0.5px_rgba(0,0,0,0.1)]">
                ALTRASTACK
              </h1>
            </div>
          </div>
          <div className="relative flex justify-center w-full h-1/4 text-center text-white">
            <p className="z-40 w-3/4 text-md font-semibold md:text-xl text-center drop-shadow-[0_4px_4px_rgba(0,0,0,1)]">
              Maximizando retornos con el poder de la automatización y la tecnología
            </p>
          </div>
          {/* <img
            src={city}
            alt="City Silhouette"
            className="z-30 absolute -bottom-[50px] left-1/2 transform -translate-x-1/2 w-full"
          /> */}
        
        
      </div>
    )
  }
  