//React
import React from 'react';
//libaries

//components

//redux
import { connect, } from 'react-redux'

//helpers
import { displayPercent2} from '../Helpers';

//UI
import {
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon
  } from '@heroicons/react/24/outline'

const Portfolio = ({
  userAccountData
}) => {

    return(
        <>
        {userAccountData?
            <div className='flex flex-col mt-4'>
                {/* todo */} 
                <div className='flex flex-row w-full'>
                    <div className='w-1/2 border mx-2 border-zinc-600 rounded-lg p-2 grid grid-cols-1'>
                        <div className='w-full flex justify-between pb-2'>
                            <div className='flex flex-col items-start'>
                                <p className='text-white text-sm'>Capital en Cash</p>
                                <p className='text-white font-semibold'>{displayPercent2(userAccountData.port1.free_cap_pctg)} </p>
                            </div>
                            <div className='flex flex-col items-end'>
                                <p className='text-[#365486] text-sm text-right'>Capital en Trade</p>
                                <p className='text-[#365486] font-semibold'>{displayPercent2(userAccountData.port1.in_trade_pctg)} </p>
                            </div>

                        </div>
                        <div className='flex h-3 flex-row'>
                            <div className='bg-gray-900 text-transparent rounded-l-sm' 
                            style={{width: displayPercent2(userAccountData.port1.free_cap_pctg)}}></div>
                            <div className='bg-[#365486] text-transparent rounded-r-sm'
                            style={{width: displayPercent2(userAccountData.port1.in_trade_pctg)}}>-</div>

                        </div>

                    </div>
                    <div className='w-1/2 border mx-2 border-zinc-600 rounded-lg p-2 grid grid-cols-1'>
                        <div className='w-full flex justify-between pb-2'>
                            <div className='flex flex-col items-start'>
                                <p className='text-emerald-600 text-sm'>Capital en Compra</p>
                                <p className='text-emerald-600 font-semibold'>{displayPercent2(userAccountData.port1.pctg_longs)} </p>
                            </div>
                            <div className='flex flex-col items-end'>
                                <p className='text-rose-600 text-sm text-right'>Capital en Venta</p>
                                <p className='text-rose-600 font-semibold'>{displayPercent2(userAccountData.port1.pctg_shorts)} </p>
                            </div>

                        </div>
                        <div className='flex h-3 flex-row'>
                            <div className='bg-emerald-600 text-transparent rounded-l-sm' 
                            style={{width: displayPercent2(userAccountData.port1.pctg_longs)}}></div>
                            <div className='bg-gray-900 text-transparent rounded-r-sm'
                            style={{width: displayPercent2(userAccountData.port1.free_cap_pctg)}}>-</div>
                            <div className='bg-rose-600 text-transparent rounded-r-sm'
                            style={{width: displayPercent2(userAccountData.port1.pctg_shorts)}}>-</div>

                        </div>

                    </div>
                </div>
                {/* assets */}
                <div className='flex flex-col md:flex-row items-start mt-2 '>
                    
                    {/* longs */} 
                    <div className='w-full md:w-1/2 flex flex-col px-4 pb-2 h-full text-white '>
                        <h3 className='w-full m-2 flex justify-center font-semibold'>
                            <span>Activos en Compra</span>
                            <ArrowTrendingUpIcon className='pl-1 w-6 text-emerald-600 transition ease-in-out delay-150' /> 
                        </h3>
                        <div>
                            <div className='text-xs md:text-sm my-1 md:px-6 py-2 flex flex-row justify-between font-semibold'>
                                <div className='w-1/3 flex flex-row justify-start items-center'>
                                    Criptopar
                                </div>
                                <div className='flex items-center w-1/3 justify-center'>
                                    % del Portfolio
                                </div>
                                <div className='flex items-center w-1/3 justify-center'>
                                    Unrealized PNL
                                </div>
                        

                            </div>
                            {Object.keys(userAccountData.port1.long_assets).map((asset, index) => (
                                <div key={index} className='my-1 md:px-6 py-2 text-[11px] md:text-xs flex flex-row justify-between border-t border-[#5C8374] hover:bg-zinc-600 cursor-pointer'>
                                    <div className='w-1/3 flex flex-row justify-start items-center'>
                                        <div className='px-2'>
                                            <span>{asset}</span>:
                                        </div>
                                    </div>
                                    <div className='flex items-center text-md w-1/3 justify-center'>
                                        <div className='w-3/4 h-full overflow-hidden bg-gray-900 border rounded-full text-transparent flex flex-row justify-items-star'>
                                            <div className='position:relative h-full text-left text-white px-2'
                                            >{displayPercent2(userAccountData.port1.long_assets[asset].pctg_port)}</div>
                                            <div className='position:relative h-full bg-[#365486] text-right text-white'
                                            style={{width: displayPercent2(userAccountData.port1.long_assets[asset].pctg_port)}}></div>
                                        </div>
                                    </div>
                                    <div className='flex items-center text-md w-1/3 justify-center'>
                                        <span className='text-right'>{displayPercent2(userAccountData.port1.long_assets[asset].unr_pnl)}</span>
                                    </div>

                                </div>
                            ))}

                        </div>
                        
                    </div>
                    {/* shorts */}
                    <div className='w-full md:w-1/2 flex flex-col px-4 pb-2 h-full text-white'>
                        <h3 className='w-full m-2 flex justify-center font-semibold'>
                            <span>Activos en Venta</span>
                            <ArrowTrendingDownIcon className='pl-1 w-6 text-rose-600 transition ease-in-out delay-150' />
                        </h3>
                        <div>
                        <div className='text-xs md:text-sm my-1 md:px-6 py-2 flex flex-row justify-between font-semibold'>
                                <div className='w-1/3 flex flex-row justify-start items-center'>
                                    Criptopar
                                </div>
                                <div className='flex items-center w-1/3 justify-center'>
                                    % del Portfolio
                                </div>
                                <div className='flex items-center w-1/3 justify-center'>
                                    Unrealized PNL
                                </div>
                        

                            </div>
                            {Object.keys(userAccountData.port1.short_assets).map((asset, index) => (
                                <div key={index} className='my-1 md:px-6 py-2 text-[11px] md:text-xs flex flex-row justify-between border-t border-[#5C8374] hover:bg-zinc-600 cursor-pointer'>
                                    <div className='w-1/3 flex flex-row justify-start items-center'>
                                        <div className='px-2'>
                                            <span>{asset}</span>:
                                        </div>
                                    </div>
                                    <div className='flex items-center text-md w-1/3 justify-center'>
                                        <div className='w-3/4 h-full overflow-hidden bg-gray-900 border rounded-full text-transparent flex flex-row justify-items-star'>
                                            <div className='position:relative h-full text-left text-white px-2'
                                            >{displayPercent2(userAccountData.port1.short_assets[asset].pctg_port)}</div>
                                            <div className='position:relative h-full bg-[#365486] text-right text-white'
                                            style={{width: displayPercent2(userAccountData.port1.short_assets[asset].pctg_port)}}></div>
                                        </div>
                                    </div>
                                    <div className='flex items-center text-md w-1/3 justify-center'>
                                        <span className='text-right'>{displayPercent2(userAccountData.port1.short_assets[asset].unr_pnl)}</span>
                                    </div>

                                </div>
                            ))}

                        </div>
                        
                    </div>
                    
                </div>
            </div>
            :
            <span>
                cargando
            </span>

        }
        
        </>

        
    )
}

const mapStateToProps = state => ({

    userAccountData: state.userData.userAccountData,
})

export default connect(mapStateToProps,{

}) (Portfolio)