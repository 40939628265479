
//assets
import bground from '../../assets/videos/tech2.mp4'
// helperse

 function BannerHome() 
  { 

 
    return (
      <div className="relative z-0 w-full h-[105px] md:h-[125px] overflow-hidden">
          <video
          className="absolute z-10 top-0 left-0 w-full h-[200px] object-cover"
          src={bground}
          autoPlay
          loop
          muted
          />
          <div className="absolute z-20 mt-14 flex flex-col items-center justify-center w-full text-center">
            <div>
              <h1 className="text-2xl md:text-5xl font-bold mb-4  text-slate-100 drop-shadow-[0_0.5px_0.5px_rgba(0,0,0,0.1)]">
                ALTRASTACK
              </h1>
            </div>    
          </div>
      </div>
    )
  }
  
  
  export default BannerHome