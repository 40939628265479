//React
import React from 'react';
//components
import Layout from "../hocs/Layout"

const Admin = () => {
    return(
        <Layout>
            <div className="text-orange-500">
            Admin
            </div>
        </Layout>
    )
}

export default Admin