import axios from "axios";
import moment from 'moment';

export const formatDates = (inputDateString) => {
    const date = moment(inputDateString);
    return date.format('DD-MM-YYYY HH:mm');
}


export const formatMoney = (amount, locale = 'en-US', currency = 'USD') => {
        // Format amount without truncating
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        }).format(amount);
    }

export const pos_neg = (amount) => {
    if(amount>0){
        return '+'
    }else{
        return ''
    }
}


export const displayPercent = (percent, fallback, digits = 1) =>
        (percent === null || percent === undefined) ? fallback : `${(parseFloat(percent)).toFixed(digits)}%`;

export const displayPercent2 = (percent, fallback, digits = 2) =>
    (percent === null || percent === undefined) ? fallback : `${(parseFloat(percent)*100).toFixed(digits)}%`;

export const capitalize = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    }



export const change_password = async (new_password, current_password) => {
    
    const config ={
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    }
    const body = JSON.stringify({
        new_password,
        current_password,
    });
    
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/set_password/`, body, config);
        if (res.status === 204){
            return true
  
        }else{
            console.log(res)
            return null
            
        }
    }catch(err){
            return null

    }

} 


export const register = async (id_run, user_name, email, password) => {
    
    const config ={
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    }
    const body = JSON.stringify({
        id_run,
        user_name,
        email,
        password
    });

    
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config);
        if (res.status === 201){
           console.log(res)
            return res.data
  
        }else{
            console.log(res)
            return null
            
        }
    }catch(err){
            return null

    }

} 


export const request_transaction = async (inOut, amount, description) => {
    
    const config ={
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    }

    const body = JSON.stringify({
        inOut,
        amount,
        description,
    });

    
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/altradata/create_transaction`, body, config);
        if (res.status === 200){
           console.log(res)
            return res.data
  
        }else{
            console.log(res)
            return null
            
        }
    }catch(err){
            return null

    }

} 


export const contac_mss = async (
    asunto,
    msg,
    email,
    tel) => {
    
    const config ={
        headers:{
            'Content-Type': 'application/json',
        }
    }

    const body = JSON.stringify({
        asunto,
        msg,
        email,
        tel
    });

    
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/altradata/contac_mss`, body, config);
        if (res.status === 200){
           console.log(res)
            return true
  
        }else{
            console.log(res)
            return false
            
        }
    }catch(err){
            return false

    }

} 


export const request_reset_psw = async (
    email,
    tel) => {
    
    const config ={
        headers:{
            'Content-Type': 'application/json',
        }
    }

    const body = JSON.stringify({
        email,
    });

    
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/altradata/reset_pss_request`, body, config);
        if (res.status === 200){
           console.log(res)
            return true
  
        }else{
            console.log(res)
            return false
            
        }
    }catch(err){
            return false

    }

} 

export const request_account = async (
        userName,
        email,
        tel,
        anualIncome,
        expInvest,
        assetsInvest,
        hasLost,
        investCap,
        investGoal,
        investTime,
        riskAcknow,
        howYouknow,) => {
    
    const config ={
        headers:{
            'Content-Type': 'application/json',
        }
    }

    const body = JSON.stringify({
        userName,
        email,
        tel,
        anualIncome,
        expInvest,
        assetsInvest,
        hasLost,
        investCap,
        investGoal,
        investTime,
        riskAcknow,
        howYouknow,
    });

    
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/altradata/request_new_account`, body, config);
        if (res.status === 200){
           console.log(res)
            return true
  
        }else{
            console.log(res)
            return false
            
        }
    }catch(err){
            return false

    }

} 






//UX
export const get_quote_category = async (cat) => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/ux/quote?cat=${cat}`, config);

        if (res.status === 200) {
            return res.data.quote;
        } else {
            return false;
        }

    } catch (err) {
        console.error('Error fetching quote:', err);
        return false;
    }
};


