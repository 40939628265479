import React from 'react';
//redux
import {Provider} from 'react-redux'
import store from './store';

//routes
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

//containers
import Home from './containers/Home';
import Error404 from './containers/Error404';
import UserBoard from './containers/UserBoard';
import Admin from './containers/Admin';
import WhatDo from './containers/WhatDo';
import RequestAccount from './containers/RequestAccount';
import Contact from './containers/Contact';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>

          <Route path="*" element={<Error404/>}/>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/investor/board/' element={<UserBoard/>}/>
          <Route exact path='/investor/board/:tabId' element={<UserBoard/>}/>
          <Route exact path='/admin/' element={<Admin/>}/>
          <Route exact path='/whatwedo/' element={<WhatDo/>}/>
          <Route exact path='/contact/' element={<Contact/>}/>
          <Route exact path='/requestacc/' element={<RequestAccount/>}/>


        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
