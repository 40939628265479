//import { ToastContainer } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

//import { check_authenticated, load_user, refresh } from '../redux/actions/auth';
//react
import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
//components
import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';

const Layout = (props) => {

    useEffect(() => {
        
    }, []);

    return(
        <div className='bg-[#040D12]'>
            <Navbar/>
            {/* <ToastContainer autoClose={5000} /> */}
            {props.children}
            <Footer/>
        </div>
    )
}


export default connect(null, {
    
}) (Layout)