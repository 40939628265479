//react
import { useEffect, useState } from 'react'
//assets
import bground from '../../assets/videos/tech3.mp4'
// helperse
import { get_quote_category } from '../Helpers';

 function Banner({
  isAuthenticated,
  user
  }) 
  { 
    const [quote, setQuote] = useState(null)

    useEffect(() => {
      const fetchQuote = async (category) => {
        const result = await get_quote_category(category);
        if (result) {
            setQuote(result);
        } else {
            console.error('Failed to fetch quote');
        }
      };
      
      if (isAuthenticated) {
        fetchQuote('keepgoing')
      } else {
        fetchQuote('action')
      }
    }, [isAuthenticated]);

 
    return (
      <div className="relative w-full md:h-[195px] h-[180px] overflow-hidden">
          <video
          className="absolute z-10 top-0 left-0 w-full h-[200px] object-cover"
          src={bground}
          autoPlay
          loop
          muted
          />
          <div className="absolute z-20 mt-14 flex flex-col items-center justify-center w-full text-center">
            <div>
              {
              isAuthenticated&&user?
              <h1 className="text-2xl md:text-5xl font-bold mb-4  text-slate-100 drop-shadow-[0_0.5px_0.5px_rgba(0,0,0,0.1)]">
                {(user.user_name).toUpperCase()}
              </h1>
              :
              <h1 className="text-2xl md:text-5xl font-bold mb-4  text-slate-100 drop-shadow-[0_0.5px_0.5px_rgba(0,0,0,0.1)]">
                ALTRASTACK
              </h1>
              }
            </div>
            <div className="flex justify-center w-4/5 md:w-2/3 text-white">
                {quote?
                <div className='w-full pt-2 border-t-2 flex flex-row'>
                  <p className="w-full z-auto text-xs md:text-sm text-center drop-shadow-[0_4px_4px_rgba(0,0,0,1)] italic">
                    "{quote.quote}"<span className='not-italic font-semibold'> - {quote.author}</span>
                  </p>
                </div>
                :
                <></>
                }
            </div>        
          </div>
      </div>
    )
  }
  
  
  export default Banner