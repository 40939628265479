
import {
    GET_ACCOUNT_DATA_SUCCESS,
    GET_ACCOUNT_DATA_FAIL,
    GET_USER_TRANSACTIONS_SUCCESS,
    GET_USER_TRANSACTIONS_FAIL,

} from '../actions/types'



const initialState = {
    userAccountData: null,
    transactions: null,
    trans_count: null,
    
}

export default function userData(state = initialState, action){
    const {type, payload} = action;

    switch(type){

        //STATES FOR LOGIN AND AUTHENTICATION
        case GET_ACCOUNT_DATA_SUCCESS:
            return{
                ...state,
                userAccountData: payload,
            }
        case GET_ACCOUNT_DATA_FAIL:
            return{
                ...state,
                userAccountData: null,
            }
        case GET_USER_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                listTransactions: payload.list_transactions,
                trans_count: payload.count
                //count: payload.count,
                //next: payload.next,
                //previous: payload.previous,
            }
        case GET_USER_TRANSACTIONS_FAIL:
            return {
                ...state,
                transactions: null,
                trans_count: null
                //count: null,
                //next: null,
                //previous: null,
            }
            
        default:
            return state

    }

}