//React
import React from 'react';
import { useEffect} from 'react'
//components
import Layout from "../hocs/Layout"
import Banner from '../components/userBoard/Banner';
import RetsBand from '../components/userBoard/RetsBand';
import DataBoard from '../components/userBoard/DataBoard';
import Boss from '../components/userBoard/Boss';
//redux
import { connect } from 'react-redux'
//redux actions
import { get_user_account_data } from '../redux/actions/userData';

const UserBoard = ({
  isAuthenticated,
  user,
  get_user_account_data,userAccountData,
}) => {

    useEffect(() => {
        get_user_account_data()
    }, [isAuthenticated, user]);

    return(
        <Layout>
            <div className='flex flex-col'>
                <Banner isAuthenticated={isAuthenticated} user={user}/> 
                <RetsBand isAuthenticated={isAuthenticated} user={user} userAccountData={userAccountData} /> 
                <DataBoard isAuthenticated={isAuthenticated} user={user} userAccountData={userAccountData}/>
                {user&&user.is_staff?
                <Boss/>
                :<></>

                }
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    user: state.auth.user,
    userAccountData : state.userData.userAccountData
})

export default connect(mapStateToProps,{
    get_user_account_data,
}) (UserBoard)