import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
//components
import PlotHist from './PlotHist'
import PlotCap from './PlotCap'
import Transactions from './Transactions'
import Portfolio from './Portfolio';
//helpers
import { displayPercent, formatMoney, pos_neg, formatDates } from '../Helpers'

 function DataBoard({
  user,
  userAccountData
  }) 
  {
    //toggletabs
    const { tabId } = useParams(); // Get tabId from URL
    const navigate = useNavigate();
    const toggleTab = tabId || 'balance';
    
    //const[toggleTab,setToggleTab] = useState(1)
    const updateToggle = (tab) => {
      navigate(`/investor/board/${tab}`);
    };

    const activatedStyleGen = {
      color: '#27272a', // Change this to the desired color
      backgroundColor:'#93B1A6',
    };

    //first tab
    const[openBalance,setOpenBalance] = useState(false)
    const ToggleOpenBalance = () => {
      setOpenBalance(!openBalance)};
    

    return (
      <div className="w-full">
        <div className='text-xs md:text-base'>
          <ul className='h-10 md:h-10 grid grid-cols-3 place-items-center text-zinc-300 font-bold shadow-md shadow-[#93B1A6]'>
              <li 
              className={`h-full w-full flex justify-center items-center px-2 py-1 text-center font-bold cursor-pointer hover:bg-[#5C8374] ${toggleTab === 'balance' ? 'active' : ''}`}
              style={toggleTab === 'balance' ? activatedStyleGen : null}
              onClick={()=>updateToggle('balance')}>Balance</li>
              <li 
              className={`h-full w-full flex justify-center items-center px-2 py-1 text-center font-bold cursor-pointer hover:bg-[#5C8374] ${toggleTab === 'portfolio' ? 'active' : ''}`}
              style={toggleTab === 'portfolio' ? activatedStyleGen : null}
              onClick={()=>updateToggle('portfolio')}>Portafolio</li>
              <li 
              className={`h-full w-full flex justify-center items-center px-2 py-1 text-center font-bold cursor-pointer hover:bg-[#5C8374] ${toggleTab === 'transacs' ? 'active' : ''}`}
              style={toggleTab === 'transacs' ? activatedStyleGen : null}
              onClick={()=>updateToggle('transacs')}>Transacciones</li>
    
          </ul>
        </div>
        <div>
          {/*Tab de Rentabilidad*/}
          <div className={toggleTab==='balance'? "block":"hidden"}>
            <div className='flex justify-center text-white mt-2 w-full md:w-auto'>
              {userAccountData&&user?
                <div className='flex md:flex-row flex-col gap-2'>
                  {/*Plot*/}
                  <PlotHist dataHist={userAccountData.rent_hist} userTit={user.user_name}
                  className='md:w-2/3'/>
                  {/*estado*/}
                  <div className='md:w-1/3 flex flex-col justify-between py-4 text-sm'>
                    <div className='flex flex-row w-full justify-center '>
                      <div className='w-[135px] px-2 text-center md:text-right'>Capital:</div>
                      <div className='w-[135px] px-2'>{formatMoney(userAccountData.acc_stats.capital_activo)} USDT</div>
                    </div>
                    <div className='flex flex-row w-full justify-center'>
                      <div className='w-[135px] px-2 text-center md:text-right'>PNL:</div>
                      <div className='w-[135px] px-2'>
                        {pos_neg(userAccountData.acc_stats.pnl)}{formatMoney(userAccountData.acc_stats.pnl)} USDT 
                        ({pos_neg(userAccountData.acc_stats.ret_pct)}{displayPercent(parseFloat(userAccountData.acc_stats.ret_pct)*100)})</div>
                    </div>
                    <div className='flex flex-row w-full justify-center'>
                      <div className='w-[135px] px-2 text-right'>Capital Ingresado:</div>
                      <div className='w-[135px] px-2'>{formatMoney(userAccountData.acc_stats.capital_ingresado)} USDT</div>
                    </div>
                    <div className='flex flex-row w-full justify-center'>
                      <div className='w-[135px] px-2 text-right'>Capital Retirado:</div>
                      <div className='w-[135px] px-2'>{formatMoney(userAccountData.acc_stats.capital_retirado)} USDT</div>
                    </div>
                    <div className='flex pt-3 md:pt-1 flex-row text-xs text-gray-600 justify-center'>
                      <div className='w-[135px] px-2 text-right'>Última Actualziación:</div>
                      <div className='w-[135px] px-2'>{formatDates(userAccountData.acc_stats.ultima_actualizacion)}</div>
                    </div>
                  </div>
                </div>
                :
                <></>
              }

            </div>
            <div className='py-1 flex justify-center text-center bg-[#93B1A6] hover:bg-[#5C8374] cursor-pointer'
            onClick={ToggleOpenBalance}> 
               ↓  ↓  Ver Balance Historico  ↓  ↓ 
            </div>
            {openBalance?
            <div>
              <PlotCap dataHist={userAccountData.rent_hist} userTit={user.user_name}/>
            </div>:
            <></>
            }
            
          </div>
          {/*Tab de Portfolio*/}
          <div className={toggleTab==='portfolio'? "block":"hidden"}>
            <Portfolio/>
          </div>
          {/*Tab de Transactions*/}
          <div className={toggleTab==='transacs'? "block":"hidden"}>
            <Transactions/>
          </div>
          
        </div>
        <div className='h-2 bg-[#5C8374]'></div>        
      </div>
    )
  }
  
  
  export default DataBoard