import Plot from 'react-plotly.js';
import React, { useState, useEffect } from 'react';

function PlotHistF({ dataHist }) {
    const [date, setDate] = useState(null);
    const [value, setValue] = useState(null);

    useEffect(() => {
      if (dataHist) {
          const millisecondsArray = Object.values(dataHist.datetime);
          const dateStringsArray = millisecondsArray.map((ms) => {
              const date = new Date(ms);
              return date;
          });
          setDate(dateStringsArray);
          setValue(Object.values(dataHist.ret_cumms));
               
      }
  }, [dataHist]);


    return (
        <div className="mx-auto flex justify-center w-full md:w-auto overflow-hidden"
            style={{
                paddingTop: '1px', paddingBottom: '1px',
                marginTop: '1px',
                display: 'flex', justifyContent: 'center'
            }}>
            <Plot
                data={[
                    {
                        x: date,
                        y: value,
                        
                        line: { color: '#6A9C89' },
                        type: 'scatter',
                        mode: 'lines',
                        xaxis: 'x',
                        yaxis: 'y'
                    },
                   
                ]}
                layout={{
                    dragmode: 'pan',
                    xaxis: {
                      autorange: true,
                      tickformat: '%d-%b \n %Y', // Format string for day-month-year
                      type: 'date',
                      tickfont: { color: 'white' },
                
                  },
                      yaxis: {
                        autorange: true,
                        side: 'right',
                        type: 'linear',
                        tickfont: { color: '#6A9C89' },
                        tickformat: '.1%',
                    },
           
                    autosize: true,
                    hovermode: "x unified",
                    showlegend: false,
                    legend: {
                        x: 0, // Adjust the horizontal position of the legend inside the plot
                        y: 1.1, // Adjust the vertical position of the legend inside the plot
                    },
                    margin: {
                        l: 10, // Left margin
                        r: 50, // Right margin
                        t: 10, // Top margin
                        b: 50, // Bottom margin
                    },
                    paper_bgcolor: "#040D12",
                    plot_bgcolor: "#040D12",
                }}
                config={{ responsive: true, displayModeBar: false }}
                className='h-[250px] w-[370px] md:h-[310px] md:w-[600px]'
            />
        </div>
    );
}

export default PlotHistF;
