//redux conections
import { connect } from 'react-redux';
import { useState } from "react";

//components
import Layout from '../hocs/Layout';

//helpers
import { request_account } from '../components/Helpers';

//UI
import bground from '../assets/videos/tech3.mp4'
import { LineWave } from 'react-loader-spinner';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const RequestAccount = () => {
    const [sentOK, setSentOk] = useState(false)
    const [reqAccLoading, setReqAccLoading] = useState(false)
    const [formReqAcc, setFormReqAcc] = useState({
        userName:"",
        email:'',
        tel: '',
        anualIncome:"entre $40000USD y $80000USD",
        expInvest:"Entre 1 y 3 años",
        assetsInvest:[],
        hasLost:"No",
        investCap:"entre $2000USD y 5000USD",
        investGoal:"Buscar una nueva fuente de Ingresos",
        investTime:"Indefinido",
        riskAcknow:"No",
        howYouknow:"Recomendación de un amigo",
        
      });
    
      const onChangeReqAcc = e => setFormReqAcc({ ...formReqAcc, [e.target.name]: e.target.value})
    
      const {
        userName,
        email,
        tel,
        anualIncome,
        expInvest,
        assetsInvest,
        hasLost,
        investCap,
        investGoal,
        investTime,
        riskAcknow,
        howYouknow,
      } = formReqAcc;

      const onChangeCheckbox = e => {
        const { name, value, checked } = e.target;
    
        if (checked) {
            setFormReqAcc(prevState => ({
                ...prevState,
                [name]: [...prevState[name], value]
            }));
        } else {
            setFormReqAcc(prevState => ({
                ...prevState,
                [name]: prevState[name].filter(item => item !== value)
            }));
        }
    };

      const onSubmitReqAcc =  async (e) => {
      
        e.preventDefault();
        if(userName===''||email===''||tel===''){
            alert('No has diligenciado la informaicón requerida. Intenta de nuevo');
        }else{
            setReqAccLoading(true)
            const sent = await request_account(
                userName,
                email,
                tel,
                anualIncome,
                expInvest,
                assetsInvest,
                hasLost,
                investCap,
                investGoal,
                investTime,
                riskAcknow,
                howYouknow)
            console.log(sent)
            if (sent === true) {
                setReqAccLoading(false)
                setSentOk(true)
                
            } else {
                setReqAccLoading(false)
                alert('Error enviando la solicitud. Intenta de nuevo.');
            }
        }

        
    }


    return(
             
        <Layout>
            <div className="relative w-full h-[110px] md:h-[135px] overflow-hidden">
                <video
                className="absolute z-10 top-0 left-0 w-full h-[200px] object-cover"
                src={bground}
                autoPlay
                loop
                muted
                />
                <div className="absolute z-20 mt-14 flex flex-col items-center justify-center w-full text-center">
                    <div>
                    
                    <h1 className="text-2xl md:text-5xl font-bold mb-4  text-slate-100 drop-shadow-[0_0.5px_0.5px_rgba(0,0,0,0.1)]">
                        {userName}
                    </h1>
                    
                    </div>
                          
                </div>
            </div>
            <div className="relative w-full z-20 shadow-[rgba(0,15,0,0.1)_0px_-2px_3px_2px] shadow-[#5C8374]">
            </div>
            <div className='text-[#93B1A6] px-10 md:p-10 flex flex-col justify-center items-center mb-56 sm:mb-10'>
             
                <h2 className='text-left font-semibold text-lg md:text-2xl'>Solcitud de cuenta</h2>
           

                {sentOK?
                <div className='md:py-20 py-12 w-full md:w-1/2'>
                    <div className='rounded-full overflow-hidden flex justify-center py-4 mb-4'>
                        <CheckCircleIcon className='w-12 bg-emerald-600 text-white transition ease-in-out delay-150 rounded-full' />
                    </div>  
                    <div className='text-justify md:text-lg'>
                    {userName} Tu información ha sido enviada a nuestro equipo para ser verificada. Nos contactaremos contido para indicarte como proceder.
                    </div> 
                </div>
                :
                <form className="mt-4 w-full ">
                    <div className="text-sm flex justify-center ">
                        <div className='flex flex-col w-full md:max-w-6xl sm:flex-row gap-2 mb-2'>
                            {/* user name */}
                            <div className='sm:w-1/3'>   
                                <label htmlFor="userName" className='p-1 text-[#93B1A6] flex items-start pt-2 text-sm md:text-base'>
                                Nombre de Usuario <span className='pl-1 italic'>(sin espacios)</span> 
                                </label>
                                <input
                                id="userName"
                                name="userName"
                                value={userName}
                                onChange={e=>onChangeReqAcc(e)}
                                placeholder="Que nombre de usuario deseas?"
                                type='text'
                                className="mb-1 text-sm md:text-base text-left relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-gray-500 focus:z-10 ]"
                                required
                                />
                            </div>
                            {/* email */}
                            <div className='sm:w-1/3'>   
                                <label htmlFor="email-address" className="p-1 text-[#93B1A6] flex items-start pt-2 text-sm md:text-base">
                                Correo electrónico
                                </label>
                                <input
                                id="email-address"
                                name="email"
                                value={email}
                                onChange={e=>onChangeReqAcc(e)}
                                type='email'
                                placeholder="Email de contacto..."
                                className="mb-1 text-sm md:text-base text-left relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-gray-500 focus:z-10 ]"
                                required
                                />
                            </div>
                            {/* telefono */}
                            <div className='sm:w-1/3'>   
                                <label htmlFor="tel" className="p-1 text-[#93B1A6] flex items-start pt-2 text-sm md:text-base">
                                Teléfono
                                </label>
                                <input
                                id="tel"
                                name="tel"
                                value={tel}
                                onChange={e=>onChangeReqAcc(e)}
                                type='tel'
                                placeholder="Número móvil de Contacto..."
                                className="mb-1 text-sm md:text-base text-left relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-gray-500 focus:z-10"
                                required
                                />
                            </div>
                        </div>
                
                    </div>
                    <div className='flex items-center justify-center'>
                        <div className='w-2/3 border-t border-[#5C8374] h-1'></div>
                    </div>
                        <div className='flex justify-center'>
                        <p className='text-sm md:text-base md:max-w-6xl mt-4'>Los siguientes datos NO serán almacenados, se usarán exclusivamente para construir el perfil de inversión del usuario y verificar si es apto para abrir una cuenta de inversión con ALTRASTACK.</p>
                        </div>
                        <div className='w-full flex justify-center'>

                            <div className='flex flex-col mt-5 gap-y-4 md:w-2/3 justify-center items-center'>
                                    {/* ingresos */}
                                    <div className="leading-3 w-full">   
                                        <label htmlFor="anualIncome" className="text-sm md:text-base">
                                        Ingresos Anuales
                                        </label>
                                        <select
                                        id="anualIncome"
                                        name="anualIncome"
                                        value={anualIncome}
                                        onChange={e=>onChangeReqAcc(e)}
                                        className="mb-1 mt-2 text-sm md:text-base relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-[#5C8374] focus:z-10"
                                        >
                                            <option key={1} value={"menor a $15000USD"}>
                                                {"menor a $15000USD"} 
                                            </option>
                                            <option key={2} value={"entre $15000USD y 40000USD"}>
                                                {"entre $15000USD y 40000USD"} 
                                            </option>
                                            <option key={3} value={"entre $40000USD y $80000USD"}>
                                                {"entre $40000USD y $80000USD"} 
                                            </option>
                                            <option key={4} value={"entre $80000USD y $120000USD"}>
                                                {"entre $80000USD y $120000USD"} 
                                            </option>
                                            <option key={5} value={"mayor a $120000USD"}>
                                                {"mayor a $120000USD"} 
                                            </option>
                                        </select>
                                    </div>
                                    {/* experiencia */}
                                    <div className="leading-3 w-full">   
                                        <label htmlFor="expInvest" className="text-sm md:text-base">
                                        Que experiencia tienes en inversiones?
                                        </label>
                                        <select
                                        id="expInvest"
                                        name="expInvest"
                                        value={expInvest}
                                        onChange={e=>onChangeReqAcc(e)}
                                        className="mb-1 mt-2 text-sm md:text-base relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-[#5C8374] focus:z-10"
                                        required
                                        >
                                            <option key={1} value={"Nada"}>
                                                {"Nada"} 
                                            </option>
                                            <option key={2} value={"Menos de 1 año invertiendo"}>
                                                {"Menos de 1 año invertiendo"} 
                                            </option>
                                            <option key={3} value={"Entre 1 y 3 años"}>
                                                {"Entre 1 y 3 años"} 
                                            </option>
                                            <option key={4} value={"Más de 3 años"}>
                                                {"Más de 3 años"} 
                                            </option>
                                        </select>
                                    </div>
                                    {/* activos */}
                                    <div className="leading-3 w-full">   
                                        <label htmlFor="assetsInvest" className="text-sm md:text-base">
                                            ¿En qué tipos de activos has invertido?
                                        </label>
                                        <div className="flex mt-2 flex-wrap gap-x-6 gap-y-2 my-1">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name="assetsInvest"
                                                    value="RentaFija"
                                                    checked={assetsInvest.includes("RentaFija")}
                                                    onChange={onChangeCheckbox}
                                                    className="form-checkbox text-indigo-600 h-3 w-3 "
                                                />
                                                <span className="ml-1 text-sm md:text-base">Renta Fija</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name="assetsInvest"
                                                    value="FincaRaiz"
                                                    checked={assetsInvest.includes("FincaRaiz")}
                                                    onChange={onChangeCheckbox}
                                                    className="form-checkbox text-indigo-600 h-3 w-3 "
                                                />
                                                <span className="ml-1 text-sm md:text-base">Finca Raiz</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name="assetsInvest"
                                                    value="FondosInversion"
                                                    checked={assetsInvest.includes("FondosInversion")}
                                                    onChange={onChangeCheckbox}
                                                    className="form-checkbox text-indigo-600 h-3 w-3 "
                                                />
                                                <span className="ml-1 text-sm md:text-base">Fondos de Inversion</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name="assetsInvest"
                                                    value="Acciones"
                                                    checked={assetsInvest.includes("Acciones")}
                                                    onChange={onChangeCheckbox}
                                                    className="form-checkbox text-indigo-600 h-3 w-3 "
                                                />
                                                <span className="ml-1 text-sm md:text-base">Acciones</span>
                                            </label>
                                            
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name="assetsInvest"
                                                    value="Criptoactivos"
                                                    checked={assetsInvest.includes("Criptoactivos")}
                                                    onChange={onChangeCheckbox}
                                                    className="form-checkbox text-indigo-600 h-3 w-3 "
                                                />
                                                <span className="ml-1 text-sm md:text-base">Criptoactivos</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name="assetsInvest"
                                                    value="Divisas"
                                                    checked={assetsInvest.includes("Divisas")}
                                                    onChange={onChangeCheckbox}
                                                    className="form-checkbox text-indigo-600 h-3 w-3 "
                                                />
                                                <span className="ml-1 text-sm md:text-base">Divisas</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name="assetsInvest"
                                                    value="Contratos"
                                                    checked={assetsInvest.includes("Contratos")}
                                                    onChange={onChangeCheckbox}
                                                    className="form-checkbox text-indigo-600 h-3 w-3 "
                                                />
                                                <span className="ml-1 text-sm md:text-base">Contratos de Derivados (Futuros, Opciones, CFDs)</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name="assetsInvest"
                                                    value="VC"
                                                    checked={assetsInvest.includes("VC")}
                                                    onChange={onChangeCheckbox}
                                                    className="form-checkbox text-indigo-600 h-3 w-3"
                                                />
                                                <span className="ml-1 text-sm md:text-base">Empresas Privadas ó VC</span>
                                            </label>
                                        </div>
                                    </div>
                                    {/* perdidas */}
                                    <div className="leading-3 w-full">   
                                        <label htmlFor="hasLost" className="text-sm md:text-base">
                                        Ha realizado inversiones que han resultado en perdidas de capital?
                                        </label>
                                        <select
                                        id="hasLost"
                                        name="hasLost"
                                        value={hasLost}
                                        onChange={e=>onChangeReqAcc(e)}
                                        className="mb-1 text-sm md:text-base mt-2 relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-[#5C8374] focus:z-10"
                                        required
                                        >
                                            <option key={1} value={"Si"}>
                                                {"Si"} 
                                            </option>
                                            <option key={2} value={"No"}>
                                                {"No"} 
                                            </option>
                                        
                                        </select>
                                    </div>
                                    {/* capitaltoInvest */}
                                    <div className="leading-3 w-full">   
                                        <label htmlFor="investCap" className="text-sm md:text-base">
                                        Cuanto cápital tiene pensado invertir en este ALTRASTACK?
                                        </label>
                                        <select
                                        id="investCap"
                                        name="investCap"
                                        value={investCap}
                                        onChange={e=>onChangeReqAcc(e)}
                                        className="mb-1 mt-2 text-sm md:text-base relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-[#5C8374] focus:z-10"
                                        required
                                        >
                                            <option key={1} value={"< $2000USD"}>
                                                {"< $2000USD"} 
                                            </option>
                                            <option key={2} value={"entre $2000USD y 5000USD"}>
                                                {"entre $2000USD y 5000USD"} 
                                            </option>
                                            <option key={3} value={"entre $5000USD y $15000USD"}>
                                                {"entre $5000USD y $15000USD"} 
                                            </option>
                                            <option key={4} value={"mayor a $15000USD"}>
                                                {"mayor a $15000USD"} 
                                            </option>
                                        </select>
                                    </div>
                                    {/* objetivo */}
                                    <div className="leading-3 w-full">   
                                        <label htmlFor="investGoal" className="text-sm md:text-base">
                                        Cual es su objetivo al invertir en ALTRASTACK?
                                        </label>
                                        <select
                                        id="investGoal"
                                        name="investGoal"
                                        value={investGoal}
                                        onChange={e=>onChangeReqAcc(e)}
                                        className="mb-1 mt-2 text-sm md:text-base relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-[#5C8374] focus:z-10"
                                        required
                                        >
                                            <option key={1} value={"Ahorro ó preservación de capital"}>
                                                {"Ahorro ó preservación de capital"} 
                                            </option>
                                            <option key={2} value={"Diversificación en Inversiones"}>
                                                {"Diversificación en Inversiones"} 
                                            </option>
                                            <option key={3} value={"Buscar una nueva fuente de Ingresos"}>
                                                {"Buscar una nueva fuente de Ingresos"} 
                                            </option>
                                            <option key={4} value={"Multiplicación de capital"}>
                                                {"Multiplicación de capital"} 
                                            </option>
                                        
                                        </select>
                                    </div>
                                    {/* keepCapital */}
                                    <div className="leading-3 w-full">   
                                        <label htmlFor="investTime" className="text-sm md:text-base">
                                        Si la inversión está cumpliendo con sus espectativas, cuanto tiempo mantendría su capital en ALTRASTACK?
                                        </label>
                                        <select
                                        id="investTime"
                                        name="investTime"
                                        value={investTime}
                                        onChange={e=>onChangeReqAcc(e)}
                                        className="mb-1 mt-2 text-sm md:text-base relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-[#5C8374] focus:z-10"
                                        required
                                        >
                                            <option key={1} value={"3 meses"}>
                                                {"3 meses"} 
                                            </option>
                                            <option key={2} value={"menos de 12 meses"}>
                                                {"menos de 12 meses"} 
                                            </option>
                                            <option key={3} value={"más de 1 año"}>
                                                {"más de 1 año"} 
                                            </option>
                                            <option key={4} value={"Indefinido"}>
                                                {"Indefinido"} 
                                            </option>
                                            <option key={5} value={"Ingresaría más capital"}>
                                                {"Ingresaría más capital"} 
                                            </option>
                                        
                                        </select>
                                    </div>
                                    {/* riesgos */}
                                    <div className="leading-3 w-full">   
                                        <label htmlFor="riskAcknow" className="text-sm md:text-base">
                                        Está conciente de los riesgos y beneficios de asociados a invertir en renta variable y criptomonedas?
                                        </label>
                                        <select
                                        id="riskAcknow"
                                        name="riskAcknow"
                                        value={riskAcknow}
                                        onChange={e=>onChangeReqAcc(e)}
                                        className="mb-1 mt-2 text-sm md:text-base relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-[#5C8374] focus:z-10"
                                        required
                                        >
                                            <option key={1} value={"Si"}>
                                                {"Si"} 
                                            </option>
                                            <option key={2} value={"No"}>
                                                {"No"} 
                                            </option>
                                        </select>
                                    </div>
                                    {/* ComoseEntero */}
                                    <div className="leading-3 w-full">   
                                        <label htmlFor="howYouknow" className="text-sm md:text-base">
                                        Como se enteró de ALTRASTACK?
                                        </label>
                                        <select
                                        id="howYouknow"
                                        name="howYouknow"
                                        value={howYouknow}
                                        onChange={e=>onChangeReqAcc(e)}
                                        className="mb-1 mt-2 text-sm md:text-base relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-[#5C8374] focus:z-10"
                                        required
                                        >
                                            <option key={1} value={"Recomendación de un amigo"}>
                                                {"Recomendación de un amigo"} 
                                            </option>
                                            <option key={2} value={"Redes Sociales"}>
                                                {"Redes Sociales"} 
                                            </option>
                                            <option key={3} value={"Busquedas en Internet"}>
                                                {"Busquedas en Internet"} 
                                            </option>
                                            <option key={4} value={"Otro"}>
                                                {"Otro"} 
                                            </option>              
                                        </select>
                                    </div>
                                    
                                <button
                                type="submit"
                                className="w-autp px-5 py-1 cursor-pointer rounded-sm bg-[#365486] hover:bg-stone-700 text-white"
                                onClick={onSubmitReqAcc}
                                >
                                    {
                                    reqAccLoading===true?
                                    <div className='relative h-6 flex justify-center w-full items-self-end overflow-hidden'>
                                        <div className='absolute flex justify-center ml-6 -bottom-3'>
                                        <LineWave
                                            height="70"
                                            width="70"
                                            color="white"
                                            ariaLabel="line-wave-loading"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <span className='h-6 text-sm md:text-lg'>Solicitar Cuenta</span>
                                    }
                                </button>
                            </div>
                        </div>
                  
                </form>
                }
            </div>
        </Layout>

        
    )
}

const mapStateToProps = state => ({


})

export default connect(mapStateToProps,{


}) (RequestAccount)