import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk'; // Named export `thunk`
import rootReducer from './redux/reducers';

// Initial state
const initialState = {};

// Middleware
const middleware = [thunk];

// Use Redux DevTools if available, otherwise fallback to regular compose
const composeEnhancers = 
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// Create the store using the fallback compose function
const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
);

export default store;
