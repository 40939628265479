//redux conections
import { connect } from 'react-redux';
import { get_home_data } from '../redux/actions/home';
//components
import Layout from "../hocs/Layout"
import HeroSection from '../components/home/HeroSection';
import UsHome from '../components/home/UsHome';
import ProductsHome from '../components/home/ProductsHome';

const Home = ({  
    get_home_data
}) => {

    
    get_home_data()
    

    return(
        <Layout>
           <HeroSection/> 
           <div className="relative w-full z-20 shadow-[rgba(0,15,0,0.1)_0px_-2px_3px_2px] shadow-[#5C8374]">
            </div>
           <UsHome/>
           <ProductsHome/>
        </Layout>
    )
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps,{
    get_home_data
}) (Home)