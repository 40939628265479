import { combineReducers } from 'redux';
import auth from "./auth";
import userData from "./userData";
import homeData from './home';
/* 
import Categories from './categories';
import Shipping from './shipping';
import Payment from './payment';
import Orders from './orders';
import Coupons from './coupons';
import Profile from './profile';
import Wishlist from './wishlist';
import Reviews from './reviews'; */

export default combineReducers({
    
    auth,
    userData,
    homeData,

})