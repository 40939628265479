import{
    GET_ACCOUNT_DATA_SUCCESS,
    GET_ACCOUNT_DATA_FAIL,
    GET_USER_TRANSACTIONS_SUCCESS,
    GET_USER_TRANSACTIONS_FAIL,
} from './types'

import axios from 'axios'



export const get_user_account_data = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };

    try { 
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/altradata/user_account_data`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ACCOUNT_DATA_SUCCESS,
                payload: res.data
            });
            return 200
        } else {
            dispatch({
                type: GET_ACCOUNT_DATA_FAIL
            });
        }

    } catch(err){
        dispatch({
            type: GET_ACCOUNT_DATA_FAIL
        });
        return 500
    }
}


export const get_user_transactions = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };

    try { 
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/altradata/user_transactions`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_USER_TRANSACTIONS_SUCCESS,
                payload: res.data
            });
            return 200
        } else {
            dispatch({
                type: GET_USER_TRANSACTIONS_FAIL
            });
        }

    } catch(err){
        dispatch({
            type: GET_USER_TRANSACTIONS_FAIL
        });
        return 500
    }
}
