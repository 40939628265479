import PlotHistF from './PlotHistF'

//redux conections
import { connect } from 'react-redux';
import { get_home_data } from '../../redux/actions/home';

//ui
import { LineWave } from 'react-loader-spinner';

function ProductsHome({
    homeData
  }) 
  {
    return (
      <>
        <div className="py-6 w-full h-auto bg-[#040D12] flex justify-center">
          <div className='md:max-w-6xl'>
            <div className='px-4 md:px-10 flex flex-col text-[#93B1A6]'>
              <div className='cursor-pointer relative w-full text-left text-xl font-bold md:text-2xl  hover-underline'>
                Rendimientos historicos
              </div>
              <div>
                {homeData?
                <PlotHistF dataHist={homeData.rent_hist}/>
                :
                <div className='w-full flex justify-center'>
                  <div className='relative h-[250px] w-[370px] md:h-[310px] md:w-[600px] flex justify-center items-center'>
                    <div className='absolute flex justify-center ml-[70px] bottom-30'>
                      <LineWave
                        height="200"
                        width="200"
                        color="white"
                        ariaLabel="line-wave-loading"
                        />
                    </div>
                  </div>
                </div>

                }
              </div>
            </div> 
            <div className='text-[#93B1A6] p-2 text-xs md:text-sm'>
              <h3 className='font-semibold'>
                Disclaimer:
              </h3>
              <p className='text-justify'>
              Los datos de rentabilidad mostrados representan rentabilidades históricas, y es importante tener en cuenta que rentabilidades pasadas no garantizan resultados futuros. 
              La información presentada se actualiza cada 12h y abarca todos los costes asociados inherentes al producto. Esto garantiza que los datos de rentabilidad reflejen con exactitud
              las ganancias o pérdidas reales experimentadas por los inversores en cada momento específico. 
              </p>

            </div>         
          </div>
        </div>
      </>
    )
  }

  const mapStateToProps = state => ({
    homeData:state.homeData.homeData
})


export default connect(mapStateToProps,{
  get_home_data
}) (ProductsHome)

  