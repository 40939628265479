//react
import { NavLink } from 'react-router-dom'
//redux conections
import { connect } from 'react-redux';
import { useEffect } from "react";

//components
import Layout from "../hocs/Layout"

//assets
import bground from '../assets/videos/tech2.mp4'
const WhatDo = () => {

    useEffect(() => {
 
    }, []);


    return(
             
        <Layout>
            <div className="relative w-full h-[105px] md:h-[125px] overflow-hidden">
                <video
                className="absolute z-10 top-0 left-0 w-full h-[200px] object-cover"
                src={bground}
                autoPlay
                loop
                muted
                />
                <div className="absolute z-20 mt-14 flex flex-col items-center justify-center w-full text-center">
                    <div>
                    <h1 className="text-2xl md:text-5xl font-bold mb-4  text-slate-100 drop-shadow-[0_0.5px_0.5px_rgba(0,0,0,0.1)]">
                        ALTRASTACK
                    </h1>
                    </div>    
                </div>
            </div>
           <div className='w-full mt-1 z-40 shadow-[rgba(0,15,0,0.1)_0px_-2px_3px_2px] shadow-[#5C8374]'>
           <section className='m-3 pt-2  text-[#93B1A6] mt-1 flex justify-center'>
            <div className='grid grid-cols-2 md:max-w-6xl gap-6'>
                <div>
                    <h3 className='font-bold text-sm md:text-lg text-center'>Enfoque Científico</h3>
                    <p className='text-[11px] text-justify md:text-lg'>Empleamos estrategías basadas en análisis extensivo de datos, modelos computacionales usando probabilidad 
                        y estadística para elegir nuestras estrategías.</p>
                </div>
                <div>
                    <h3 className='font-bold text-sm md:text-lg text-center'>Criptos y Rentabilidad</h3>
                    <p className='text-[11px] text-justify md:text-lg'>Aprovechamos el comportamiento volátil de los criptoactivos para generar mayores rentabilidades que un vehículo financiero tradicional a mediano y largo plazo. </p>
                </div>
                <div>
                    <h3 className='font-bold text-sm md:text-lg text-center'>Automatización</h3>
                    <p className='text-[11px] text-justify md:text-lg'>Tenemos servidores analizando en tiempo real y operando el mercado de criptomonedas las 24 horas del día, todos los días</p>
                </div>
                <div>
                    <h3 className='font-bold text-sm md:text-lg text-center'>Diversificación</h3>
                    <p className='text-[11px] text-justify md:text-lg'>Diversificamos en diferentes lógicas de estrategias, horizontes de tiempo de largo y corto plazo y enfoques en mercados alcistas y bajistas.</p>
                </div>
            </div>
           </section>
           <section className='bg-[#93B1A6] p-2 pb-3 md:p-8 flex justify-center'>
            <div className='md:max-w-6xl'>
                <h2 className='font-bold text-base md:text-2xl'>Proceso de Inversión</h2>
                    <div className='py-3 text-base md:text-xl'>
                        <h3 className='font-semibold'>
                            Análisis de Hipotesis de mercado.
                        </h3>
                        <p className="text-justify ">
                            Examinamos datos historicos del mercado y probamos diferentes hipotesis de manera sistemática para encontrar patrones de comportamiento 
                            del precio de los activos que puedan repetirse en el futuro en diferentes entornos o contextos del mercado. 
                        </p>
                    </div>
                    <div className='py-3 text-base md:text-xl'>
                        <h3 className='font-semibold'>
                            Validación.
                        </h3>
                        <p className="text-justify ">
                            El método científico es la base de la verificación de que un comportamiento recurente y rentable no sea mero resultado del azar o la suerte. 
                            Toda estrategía la sometemos a estricta validación estadística y probabilística para incluir o descartar una estrategía del portafolio.
                        </p>
                    </div>
                    <div className='py-3 text-base md:text-xl'>
                        <h3 className='font-semibold'>
                            Trading Algorítmico.
                        </h3>
                        <p className="text-justify ">
                            Convertimos los patrones selecciodos en algoritmos computalcionaes con reglas y parametros exactos que va a ejecutar una máquina 
                            en tiempo real cada vez que las condiciones para operar esten presentes.
                        </p>
                    </div>
                    <div className='py-3 text-base md:text-xl'>
                        <h3 className='font-semibold'>
                            Diversificación.
                        </h3>
                        <p className="text-justify ">
                            Utilizando la máxima cantidad posible de patrones, estrategias y activos no correlacionados que hayan pasado por los procesos, anteriores consolidamos un portafolio 
                            de inversión, minimizando sistemáticamente el riesgo y afianzando un crecimiento de capital consistente con el tiempo.
                        </p>
                    </div>
                <div className='w-full flex justify-end'>
                    <NavLink to="/contact/" className='rounded-sm text-sm md:text-lg relative p-2 text-white bg-[#365486] items-self-end hover-underline hover:bg-[#5C8374]'>
                    Quieres saber más?
                    </NavLink>
                </div>
            </div>
           </section>

           </div>
        </Layout>

        
    )
}

const mapStateToProps = state => ({


})

export default connect(mapStateToProps,{


}) (WhatDo)