import{
    GET_HOME_DATA_SUCCES,
    GET_HOME_DATA_FAIL,
} from './types'

import axios from 'axios'

export const get_home_data = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try { 
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/altradata/home_data`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_HOME_DATA_SUCCES,
                payload: res.data
            });
            return 200
        } else {
            dispatch({
                type: GET_HOME_DATA_FAIL
            });
        }

    } catch(err){
        dispatch({
            type: GET_HOME_DATA_FAIL
        });
        return 500
    }
}