//React
import React from 'react';
import { Fragment, useEffect, useState } from 'react'
//libaries

//components


//redux
import { connect,  } from 'react-redux'
//redux actions
import { get_user_transactions} from '../../redux/actions/userData';
//helpers
import { formatMoney, formatDates, request_transaction, capitalize} from '../Helpers';

//UI
import {
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon
  } from '@heroicons/react/24/outline'
import { LineWave } from 'react-loader-spinner'

const Transacciones = ({
  isAuthenticated,
  user,
  get_user_transactions, user_transactions,  trans_count,
  userAccountData
}) => {

    useEffect(() => {
        get_user_transactions()
    }, [user]);


    //new transaction
    function formatStatus(status) {
        if(status==='pendiente'){
            return 'text-amber-300'
        }else if(status==='realizada'){
            return 'text-emerald-600'
        }else{
            return 'text-rose-600'
        }
    }

    const [newTransOpen, setNewTransOpen] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    
    const toggleOpenNewTrans = e => {
        e.preventDefault()
        setNewTransOpen(!newTransOpen);
        };
    
    const [formDataTrans, setFormDataTrans] = useState({
        inOut:'in',
        amount:0,
        description:''
        });
    
    const {
        inOut,
        amount,
        description,
        } = formDataTrans;
    
    const onChangeTrans = e => {
            setFormDataTrans({ ...formDataTrans, [e.target.name]: e.target.value})

    }

    const onSubmitCreateTransaction =  async (e) => {
        e.preventDefault();
        setCreateLoading(true)
        console.log(formDataTrans)
        const tr_created = await request_transaction(inOut, amount, description)
        console.log(tr_created)
        if (tr_created) {
            const newtrans = await get_user_transactions()
            if (newtrans===200){
                setCreateLoading(false)
                setFormDataTrans({
                    inOut:'in',
                    amount:0,
                    description:''
                    })
                alert('Se creo correctamente la transacción. Estamos verificando la información para incluir o retirar el capital de tu cuenta.')
            }else{
                setCreateLoading(false)
                setFormDataTrans({
                    inOut:'in',
                    amount:0,
                    description:''
                    })
                alert('Error creando la transaction');
            }
        } else {
            setCreateLoading(false)
            setFormDataTrans({
                inOut:'in',
                amount:0,
                description:''
                })
            alert('Error creando la transaction');
        }
    }

    function comisionsWithdraw(amount) {

        const free_cap = parseFloat(userAccountData.balance.free_trade_cap)
        const cap_to_trade = parseFloat(amount) - free_cap
        const coms = cap_to_trade*0.1/100
        const amountToReceive = parseFloat(amount) - coms 
    
        if (cap_to_trade<0){
            return {
                'amountToReceive':parseFloat(amount), 'coms':0,
            }
        }else{
            return {
                'amountToReceive':amountToReceive, 'coms':coms,
            }

        }
        
    }
    
    

    return(

        <div className='flex flex-col-reverse md:flex-row items-start'>
            {/* nueva trans */} 
            <div className='w-full md:w-1/3 flex flex-col px-4 pb-10 h-full'>

                <div className='w-full m-2 flex justify-center'>
                    <button className='m-2 text-white w-[180px] cursor-pointer rounded-sm bg-[#365486] hover:bg-stone-700 flex justify-center'
                    onClick={toggleOpenNewTrans}
                    >
                        <span>Nueva Transacción</span>
                    </button>

                </div>
                {newTransOpen?
                    <div className='text-white clear-start text-sm border border-[#5C8374] rounded-sm'>
                        {!createLoading?
                        <form className='p-1'>
                            {/* MOVIMIENTO */}
                            <div>   
                                <label htmlFor="inOut" className="">
                                Movimiento
                                </label>
                                <select
                                id="inOut"
                                name="inOut"
                                value={inOut}
                                onChange={e=>onChangeTrans(e)}
                                className="mb-1 text-xs relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-[#5C8374] focus:z-10 sm:text-sm"
                                required
                                >
                                    <option key={1} value={"in"}>
                                        Depositar
                                    </option>
                                    <option key={2} value={"out"}>
                                        Retirar
                                    </option>
                                </select>
                            </div>
                            
                            {/* MONTO */}
                            <div className="fade-in">
                                <label htmlFor="amount" className="">
                                Monto de la transacción
                                </label>
                                <input 
                                type="number" 
                                name="amount" 
                                id="amount" 
                                pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" 
                                value={amount}
                                min={0}
                                onChange={e=>onChangeTrans(e)}
                                placeholder="$1500.00"
                                className="mb-1 text-xs text-right relative block w-full appearance-none border rounded-sm border-zinc-800 px-3 py-2 bg-gray-900 text-white placeholder-gray-500 focus:z-10 sm:text-sm"
                                required
                                />
                            </div>
                            {/* COMS */}
                            {inOut==='out'?
                            <div className='text-[8px] flex flex-col'>
                                <span>Comisiones por cancelar posiciones: {formatMoney(comisionsWithdraw(amount).coms)}</span>
                                <span>Capital aprox. a recibir: {formatMoney(comisionsWithdraw(amount).amountToReceive)}</span>
                                
                            </div>:
                            <></>
                            }
                            {/* DESCRIPCION */}
                            <div>   
                                <label htmlFor="description" className="">
                                Descripción
                                </label>
                                <textarea
                                id="description"
                                name="description"
                                rows={2}
                                value={description}
                                onChange={e=>onChangeTrans(e)}
                                type='text'
                                placeholder="Opcional..."
                                className="relative block text-xs w-full appearance-none rounded-sm border border-c px-3 py-2 border-zinc-800 bg-gray-900 text-white placeholder-gray-500 focus:z-1 sm:text-sm"
                                />
                            </div>
                            <div className='flex w-full justify-end'>
                                <button className='m-2 px-10 text-white w-auto cursor-pointer rounded-sm bg-[#365486] hover:bg-stone-700 flex justify-right'
                                onClick={onSubmitCreateTransaction}
                                >
                                    Solicitar
                                </button>

                            </div>

                        </form>
                        :
                        <div className='relative h-[244px] flex justify-center w-full items-self-end overflow-hidden'>
                        <div className='absolute flex justify-center ml-10 bottom-5'>
                        <LineWave
                            height="120"
                            width="120"
                            color="white"
                            ariaLabel="line-wave-loading"
                            />
                        </div>
                        </div>
                        }
                    </div>
                    :
                    <></>
                }

            </div>
            {/* list of transactions */}
            <div className='w-full md:w-2/3 md:border-l md:border-[#5C8374] h-full md:pb-[220px]'>
                {
                user_transactions?
                <>
                {/* listado Transacciones recientes */}
                <div className='grid grid-cols-1 py-4 px-2 text-zinc-300 w-full'>
                    <div className='text-xs md:text-sm my-1 md:px-6 py-2 flex flex-row justify-between font-semibold'>
                        <div className='w-1/4 flex flex-row justify-start items-center'>
                                Movimiento
                        </div>
                        <div className='flex items-center w-1/4 justify-center'>
                               Monto
                        </div>
                        <div className='flex items-center w-1/4 justify-center'>
                            Estatus
                        </div>
                        <div className='px-2 w-1/4 text-right'>
                            Fecha
                        </div>

                    </div>
                    {user_transactions&&user_transactions.map((transaction, index) => (
                    <div key={transaction.id} 
                    //onClick={() => handleOpenTransDetail(user_transactions.id)}
                    className='my-1 md:px-6 py-2 text-[11px] md:text-xs flex flex-row justify-between border-t border-[#5C8374] hover:bg-zinc-600 cursor-pointer'>
                        <div className='w-1/4 flex flex-row justify-start items-center'>
                                {transaction.in_out === 'in'?
                                <ArrowTrendingUpIcon className='w-6 text-emerald-600 transition ease-in-out delay-150' />
                            
                                :
                                <ArrowTrendingDownIcon className='w-6 text-rose-600 transition ease-in-out delay-150' />
                                }
                                
                            <div className='px-2'>
                                {transaction.in_out === 'in'?
                                <span>Deposito</span>:
                                <span>Retiro</span>
                                }
                                
                            </div>
                        </div>
                        <div className='flex items-center text-md w-1/4 justify-center'>
                                {transaction.in_out === 'in'?
                                <span>+</span>:
                                <span>-</span>
                                }
                            <span>{formatMoney(parseFloat(transaction.amount))}</span>
                        </div>
                        <div className={`flex items-center w-1/4 justify-center ${formatStatus(transaction.status)}`}>
                            <span>{capitalize(transaction.status)}</span>
                        </div>
                        <div className='flex items-center justify-end w-1/4'>
                            <span className='text-right'>{formatDates(transaction.date)}</span>
                        </div>

                    </div>
                    ))}
                </div>
                
                </>
                
                :
                <div>NO</div>
                
                }
                
            </div>
            
        </div>
        
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    user_transactions : state.userData.listTransactions,
    trans_count : state.userData.trans_count,
    userAccountData: state.userData.userAccountData,
})

export default connect(mapStateToProps,{
    get_user_transactions,
}) (Transacciones)